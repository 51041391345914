export default {
  name: `menu`,
  entries: [
    {
      name: `production`,
      label: `STRØM`,
    },
    {
      name: `substation`,
      label: `TRANSFORMATORSTASJON`,
    },
    {
      name: `transmission`,
      label: `TRANSMISJON`,
    },
    {
      name: `distribution`,
      label: `DISTRIBUSJON`,
    },
    {
      name: `website`,
      label: `Omexom.com`,
    },
    {
      name: `contact`,
      label: `KONTAKT`,
    },
    {
      name: `toggle_fullscreen`,
      label: `HELE SKJERMEN`,
    },
    {
      name: `language_selection`,
      label: `SPRÅK`,
    },
  ]
}