export default {
  name: `map`,
  backgroundImg: require(`@/assets/map/map-background.jpeg`),
  spots: [
    {
      name: `distribution`,
      scene: `distribution`,
      coords: {
        x: 51,
        y: 30,
      },
    },
    {
      name: `production`,
      scene: `production`,
      coords: {
        x: 61,
        y: 46,
      },
    },
    {
      name: `transformation`,
      scene: `transformation_1`,
      coords: {
        x: 31,
        y: 79,
      },
    },
    {
      name: `transport`,
      scene: `transport_1`,
      coords: {
        x: 20,
        y: 51,
      },
    },
  ],
}