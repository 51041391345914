export default {
  name: `menu`,
  entries: [
    {
      name: `production`,
      label: `PRODUCTION`,
    },
    {
      name: `substation`,
      label: `TRANSFORMATION`,
    },
    {
      name: `transmission`,
      label: `TRANSPORT`,
    },
    {
      name: `distribution`,
      label: `DISTRIBUTION`,
    },
    {
      name: `website`,
      label: `Omexom.com`,
    },
    {
      name: `contact`,
      label: `CONTACT`,
    },
    {
      name: `toggle_fullscreen`,
      label: `PLEIN ÉCRAN`,
    },
    {
      name: `language_selection`,
      label: `LANGUES`,
    },
  ]
}