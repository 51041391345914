<template>
  <div
    class="loading-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
    :style="`
      background-image: url(${config.backgroundImg});
    `"
  >
    <div class="loading-title">
      {{ config.tutorial }}
    </div>
    <div class="loading-logo">
      <img :src="config.logo" alt="">
    </div>
    <div class="tutorial-parts-wrapper">
      <div
        class="tutorial-part"
        v-for="(part) in config.tutorialParts"
        :key="`${part.name}-tutorial-part`"
      >
        <img :src="part.icon" :alt="`${part.name}-icon`" />
        <div class="tutorial-part-label">{{ part.label }}</div>
      </div>
    </div>
    <div class="progress-and-action-wrapper">
      <div class="loading-bar-wrapper" v-if="isProgressDisplayed">
        <div class="loading-bar-container">
          <div class="loading-bar" :style="`width: ${progress}%;`" />
        </div>
        <div class="loading-progress">{{ progressRounded }}%</div>
        <div class="loading-text">{{ config.loadingText }}</div>
      </div>
      <a class="omexom-button" @click.prevent="close" v-else>
        {{ config.startCTA }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: false,
      default: () => 0,
    }
  },

  data: () => ({
    isProgressDisplayed: true,
  }),

  computed: {
    progressRounded() {
      return Math.ceil(this.progress);
    },
  },

  methods: {
    close() {
      this.$emit(`close`);
    },
  },

  watch: {
    progressRounded(progressRounded) {
      if (progressRounded >= 100) {
        setTimeout(() => {
          this.isProgressDisplayed = false;
        }, 1000);
      } else {
        this.isProgressDisplayed = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$loading-bar-height: 14px;
$loading-bar-border-radius: 5px;

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  justify-content: space-around;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #FFF;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .loading-title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 30px;
  }

  .loading-logo {
    width: 30%;
    max-width: 400px;
    img {
      width: 100%;
    }
  }

  .tutorial-parts-wrapper {
    width: 90%;
    max-width: 800px;
    display: flex;
    justify-content: space-around;

    .tutorial-part {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 80px;
        margin-bottom: 2em;
      }
    }
  }

  .progress-and-action-wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .loading-bar-wrapper {
    width: 50%;
    max-width: 500px;
    // padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-weight: bold;

    .loading-bar-container {
      margin-top: 6em;
      position: relative;
      width: 100%;
      height: $loading-bar-height;
      border-radius: $loading-bar-border-radius;
      background-color: rgba(255, 255, 255, 0.2);

      .loading-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: $loading-bar-height;
        border-radius: $loading-bar-border-radius;
        background-color: #FFF;
        transition: width 0.1s ease-in-out;
      }
    }

    .loading-progress {
      margin: 0.6em 0;
    }

    .loading-text {
      @include keyframes(blink) {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @include animate(blink, 1.5s, ease, infinite)
    }
  }
}
</style>