import * as THREE from 'three';

THREE.Cache.enabled = true;

export const spacesTextureLoader = new THREE.TextureLoader();

export default async function loadTexture(file) {
  return new Promise(resolve => {
    spacesTextureLoader.load(file, texture => resolve(texture));
  });
}
