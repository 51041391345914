import * as THREE from 'three'

export default class Cam {
  constructor(ap3) {
    this.init(ap3)
  }

  init(ap3) {
    this.ap3 = ap3
    this.params = {
      aziRange: { min: -60, max: 60 },
      fovRange: { min: 35, max: 85 },
      iniCamFov: 80, // FOV initial caméra
      speedRotation: 0.75, // Vitesse de rotation (drag)
      speedZoomMouseWheel: 4, // Vitesse du zoom caméra (mousewheel)
      speedZoomPinch : 4, // Vitesse du zoom caméra (pinch)
      safeZoneFactor: 1.5, // % de la largeur d'écran à balayer avant de démarrer le scroll
      smoothRotationFactor: 0.333, // // in [0, 1]  |  0 : pas d'amorti, 1 : amorti long
      smoothZoomFactor: 0.5, // // in [0, 1]  |  0 : pas d'amorti, 1 : amorti long
    }
    this.data = {
      minAzi: this.params.aziRange.min * Math.PI/180,
      maxAzi: this.params.aziRange.max * Math.PI/180,
      dragLon: 0,
      dragAzi: 0,
      softLon: 0,
      softAzi: 0,
      gyroLonSide: 0,
      gyroAziSide: 0,
      iniPinchCamFov: 0,
      camFovTarget: 0,
      speedRotation: 0,
    }
    this.data.camFovTarget = this.params.iniCamFov
    this.data.speedRotation = this.params.speedRotation
    this.hCam0 = new THREE.Object3D()
    this.hCam1 = new THREE.Object3D()
    this.cam = new THREE.PerspectiveCamera( this.params.iniCamFov, this.ap3.sizes.w / this.ap3.sizes.h, 0.1, 11 )
    this.ap3.scene.add(this.hCam0)
    this.hCam0.add(this.hCam1)
    this.hCam1.add(this.cam)
    this.ticks = []
  }

  iniCamRotation(loc) {
    if(this.ap3.gyro.enabled) { this.ap3.gyro.tick() }
    this.iRotLon = (90 - loc.lon) * Math.PI/180 - ((this.data.gyroLonSide + Math.PI) % (2 * Math.PI) - Math.PI)
    this.data.dragLon = this.iRotLon
    this.data.softLon = this.data.dragLon
    this.iRotAzi = loc.azi * Math.PI/180 - this.data.gyroAziSide
    this.data.dragAzi = this.iRotAzi
    this.data.softAzi = this.data.dragAzi
    this.updateCamOrientation()
  }

  resize() {
    this.cam.aspect = this.ap3.sizes.w / this.ap3.sizes.h
    this.cam.updateProjectionMatrix()
  }

  addTicks(tick) {
    this.ticks.push(tick)
  }

  #camTicks() {
    for (let i = 0; i < this.ticks.length; i++) { this.ticks[i]() }
  }

  mouseWheelZoom(direction) {
    this.data.camFovTarget -= direction * this.params.speedZoomMouseWheel
    this.data.camFovTarget = Math.min(this.params.fovRange.max, (Math.max(this.params.fovRange.min, this.data.camFovTarget)))
    this.ap3.time.addEvent('smoothZoom')
    this.#camTicks()
  }

  tickPinchZoom() {
    this.data.camFovTarget = this.data.iniPinchCamFov - (this.params.speedZoomPinch * (this.ap3.pointer.pinchDistance - this.ap3.pointer.iniPinchDistance)/25)
    this.data.camFovTarget = Math.min(this.params.fovRange.max, Math.max(this.params.fovRange.min, this.data.camFovTarget))
    this.ap3.time.addEvent('smoothZoom')
  }

  setIRotation() {
    this.iRotLon = this.data.dragLon
    this.iRotAzi = this.data.dragAzi
    this.data.speedRotation = this.params.speedRotation * this.ap3.cam.cam.fov / this.params.iniCamFov
  }

  tick() {
    this.data.dragLon = this.iRotLon + (this.ap3.pointer.pointer.x - this.ap3.pointer.iPointer.x) * Math.PI/2 * this.data.speedRotation
    this.data.dragAzi = this.iRotAzi - (this.ap3.pointer.pointer.y - this.ap3.pointer.iPointer.y) * Math.PI/2 * this.data.speedRotation
  }

  tickSmoothRotation() {
    const factor = Math.pow(this.params.smoothRotationFactor, 0.1) * 0.96
    this.data.softLon = this.data.softLon * factor + this.data.dragLon * (1 - factor)
    this.data.softAzi = this.data.softAzi * factor + this.data.dragAzi * (1 - factor)
    this.updateCamOrientation()
    if(!this.ap3.pointer.isDragging && Math.abs(this.data.softLon - this.data.dragLon) < 0.01 && Math.abs(this.data.softAzi - this.data.dragAzi) < 0.005) {
      this.ap3.time.remEvent('smoothRotation')
      this.data.dragLon = this.data.softLon
      this.data.dragAzi = this.data.softAzi
    }
  }

  tickSmoothZoom() {
    const factor = Math.pow(this.params.smoothZoomFactor, 0.1) * 0.96
    this.cam.fov = this.cam.fov * factor + this.data.camFovTarget * (1 - factor)
    this.cam.updateProjectionMatrix()
    if(!this.ap3.pointer.pinching && Math.abs(this.data.camFovTarget - this.cam.fov) < 0.005) {
      this.ap3.time.remEvent('smoothZoom')
      this.data.camFovTarget = this.cam.fov
    }
  }

  updateCamOrientation() {
    this.#camTicks()
    this.hCam0.rotation.y = this.data.softLon + this.data.gyroLonSide
    this.hCam1.rotation.x = this.data.softAzi + this.data.gyroAziSide
    if(this.hCam1.rotation.x > this.data.maxAzi) {
      this.data.dragAzi = Math.min(this.data.dragAzi, this.data.maxAzi - this.data.gyroAziSide)
    } else if(this.hCam1.rotation.x < this.data.minAzi) {
      this.data.dragAzi = Math.max(this.data.dragAzi, this.data.minAzi - this.data.gyroAziSide)
    }
    this.hCam1.rotation.x = Math.min(this.data.maxAzi, Math.max(this.data.minAzi, this.hCam1.rotation.x))
  }
}