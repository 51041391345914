export default {
  name: `menu`,
  entries: [
    {
      name: `production`,
      label: `POWER`,
    },
    {
      name: `substation`,
      label: `SUBSTATION`,
    },
    {
      name: `transmission`,
      label: `TRANSMISSION`,
    },
    {
      name: `distribution`,
      label: `DISTRIBUTION`,
    },
    {
      name: `website`,
      label: `Omexom.com`,
    },
    {
      name: `contact`,
      label: `CONTACT`,
    },
    {
      name: `toggle_fullscreen`,
      label: `FULL SCREEN`,
    },
    {
      name: `language_selection`,
      label: `LANGUAGES`,
    },
  ]
}