import * as THREE from 'three'
import Time from './Time'
import Sizes from './Sizes'
import Cam from './Cam'
import Gyro from './Gyro'
import Renderer from './Renderer'
import AssetsLoader from './AssetsLoader'
import Sphere from './Sphere'
import Spots from './Spots'
import Pointer from './Pointer'
import Ray from './Ray'
import LilGui from './LilGui'

export default class Ap3 {
  constructor(canvas) {
    this.canvas = canvas
    this.start()
  }

  start() {
    this.scene = new THREE.Scene()
    this.time = new Time(this)
    this.sizes = new Sizes(this)
    this.cam = new Cam(this)
    this.gyro = new Gyro(this)
    this.renderer = new Renderer(this)
    this.pointer = new Pointer(this)
    this.ray = new Ray(this)

    this.time.addEvent('render')
    this.time.addEvent('ray')
  }

  addAssets(assets, handler, name) {
    if (!this[`${name}AssetsLoader`]) {
      this[`${name}AssetsLoader`] = new AssetsLoader()
    }
    return this[`${name}AssetsLoader`].load(assets, handler)
  }

  setSphere(sphereAssets, scene = null) {
    if (!this.sphere) {
      this.sphere = new Sphere(this, sphereAssets)
    } else {
      this.sphere.setNewSceneSphere(scene, sphereAssets)
    }
  }

  setSpots(hotspots, spotsAssets, spotsEventListeners) {
    if (!this.spots) {
      this.spots = new Spots(this, hotspots, spotsAssets, spotsEventListeners)
    } else {
      this.spots.setNewSceneSpots(hotspots, spotsEventListeners)
    }
    if (process.env.NODE_ENV === `development`) {
      this.lilGui = new LilGui(this)
    }
  }

  disposeScene(scene) {
    this.sphere.disposeSceneAssets(scene)
    this.spots.disposeSpots()
    this.sphereAssetsLoader.disposeSceneTextures(scene)
  }

  destroy() {
    this.sphere.destroy()
    this.spots.destroy()
  }
}