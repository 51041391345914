<template>
  <div
    class="omexom-map"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
  >
    <div
      class="omexom-map-button"
      @click="openMap"
      :style="`background-image: linear-gradient(rgba(43, 43, 43, 0.6), rgba(43, 43, 43, 0.6)), url(${map.backgroundImg});`"
    >
      {{ map.label }}
    </div>
    <div
      class="omexom-map-wrapper"
      :class="{
        visible: open,
      }"
    >
      <img :src="map.backgroundImg" alt="omexom-map">
      <button
        class="omexom-map-spot"
        v-for="(spot) in map.spots"
        :key="`first-floor-spot-${spot.name}`"
        @click.stop="changeScene(spot.scene)"
        :style="`
          top: ${spot.coords.y}%;
          left: ${spot.coords.x}%;
        `"
      >
        <div class="label">{{ spot.label }}</div>
      </button>
      <button class="close-button" @click.stop="close">×</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    map: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
    openMap() {
      this.$emit(`open-map`);
    },
    close() {
      this.$emit(`close`);
    },
    changeScene(scene) {
      this.$emit(`change-scene`, scene);
      this.$emit(`close-menu`);
    }
  },
};
</script>

<style lang="scss">
.omexom-map {
  .omexom-map-button {
    position: absolute;
    bottom: 2em;
    left: 2em;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-size: 300%;
    background-position: top left;
    border: 10px solid rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: #ffffff;
    }
  }

  .omexom-map-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    button {
      position: absolute;
    }

    .omexom-map-spot {
      padding: 1.5em 0;
      background-color: #ffffff;
      color: #082b69;
      border: 1px solid #082b69;
      width: 100%;
      max-width: 330px;

      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        color: #ffffff;
        background-color: #082b69;
      }
    }

    .close-button {
      top: 1%;
      right: 1%;
      padding: 20px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      color: #082b69;
      border: none;
      background: none;
      font-size: 30px;
      line-height: 17px;

      transition: color 0.5s ease-in-out;

      &:hover {
        color: #1e407b;
      }
    }
  }
}
</style>