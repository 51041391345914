import * as THREE from 'three'

export default class Mats {
  constructor(mats, texs) {
    this.init()
    this.setMats(mats, texs)
  }

  init() {
    this.mats = {}
  }

  setMats(mats, texs) {
    for(const el in mats) {
      switch(mats[el].type) {
        case 'matStd':
          this.mats[el] = new THREE.MeshStandardMaterial()
          if(mats[el].diffuse) 				{ this.mats[el].map = texs[mats[el].diffuse] }
          if(mats[el].color) 					{ this.mats[el].color = new THREE.Color(mats[el].color) }
          if(mats[el].normal) 				{ this.mats[el].normalMap = texs[mats[el].normal] }
          if(mats[el].rough) 					{ this.mats[el].roughnessMap = texs[mats[el].rough] }
          if(mats[el].metal) 					{ this.mats[el].metalnessMap = texs[mats[el].metal] ; this.mats[el].metalness = 1 }
          if(mats[el].metalFac) 			{ this.mats[el].metalness = mats[el].metalFac }
          if(mats[el].alpha) 					{ this.mats[el].alphaMap = texs[mats[el].alpha] ; this.mats[el].transparent = true }
          if(mats[el].envFac) 				{ this.mats[el].envMapIntensity = mats[el].envFac }
        break
        case 'matBas':
          this.mats[el] = new THREE.MeshBasicMaterial()
          if(mats[el].map) 					{ this.mats[el].map = texs[mats[el].map] }
          if(mats[el].transparent) 	{ this.mats[el].transparent = true }
          if(mats[el].alpha) 				{ this.mats[el].alphaMap = texs[mats[el].alpha] ; this.mats[el].transparent = true }
          if(mats[el].opacity) 			{ this.mats[el].opacity = mats[el].opacity ; this.mats[el].transparent = true }
        break
        case 'matCap':
          this.mats[el] = new THREE.MeshMatcapMaterial()
          if(mats[el].matcap) { this.mats[el].matcap = texs[mats[el].matcap] }
        break
      }
      this.mats[el].name = el
    }
  }

  disposeSceneMats(scene) {
    for (let mat of Object.keys(this.mats)) {
      if (scene === mat) {
        this.mats[mat].dispose()
        delete this.mats[mat]
      }
    }
  }
}