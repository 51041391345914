export default [
  // #region DISTRIBUTION
  {
    name: `distribution`,
    title: `Distribution`,
    content: {
      columns: [
        [
          `Omexom has the skills to deliver a comprehensive project, encompassing all phases – innovation, feasibility, design, build and maintenance.`,
          `Reducing the total capital and operational expenditure of distribution networks while improving the safety, the security and the carbon footprint of the network is the key challenge driving Omexom’s offers.`,
        ],
      ],
      files: [
        {
          name: `brochure_distribution`,
          label: `Brochure`,
        },
      ],
    }
  },
  {
    name: `hotspot-phoenix`,
    title: `Phoenix`,
    content: {
      columns: [
        [
          `PHOENIX is a directional fault detector. It detects electrical faults on 15 to 20 kV lines and analyses electromagnetic fields of distribution lines without any galvanic contact.`,
        ],
      ],
      files: [
        {
          name: `flyer_phoenix`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion DISTRIBUTION
  // #region PRODUCTION
  {
    name: `renewables-energies`,
    title: `Renewable Energies`,
    content: {
      columns: [
        [
          `Omexom delivers comprehensive solutions to clients in Europe and all over the world. We are a global network of power generation experts with the capacity to deliver local projects. Thanks to our expertise on the entire electricity chain, from power generation to consumption on territories, we understand our clients’ issues and challenges.`,
          `We benefit from the local expertise of our network and who has a deep knowledge of the local technical regulations. We also provide global knowledge support with our worldwide network.`,
        ],
        [
          `We can provide a wide range of services such as project engineering, procurement, construction, operation or maintenance to decarbonize our clients’ energy mix.`,
          `We provide solutions for sustainable and low carbon footprint, secure and cost-effective power plants.`,
        ],
      ],
      files: [
        {
          name: `brochure_wind`,
          label: `Wind Brochure`,
        },
        {
          name: `brochure_hydro`,
          label: `Hydro Brochure`,
        },
        {
          name: `brochure_solar`,
          label: `Solar Brochure`,
        },
      ],
    }
  },
  {
    name: `energy-storage`,
    title: `Energy Storage`,
    content: {
      columns: [
        [
          `Energy storage is a main component of any holistic consideration of smart grids, particularly when incorporating power derived from variable, distributed and renewable energy resources.`,
          `The transition to safer more sustainable and more flexible electrical networks will benefit from the widespread deployment of Energy Storage Systems. Adaptable to all kind of situation through their technical design and location on the grid they have proven their ability to :`,
        ],
        [
          `1. Facilitate the integration of intermittent renewable energy sources`,
          `2. Reduce the consumption of fossil fuels of thermal plants through hybridization`,
          `3. Provide efficient ancillary services to the grid, such as frequency & voltage control`,
          `4. Relieve congestions and defer the need for infrastructure upgrades`,
        ],
      ],
      files: [
        {
          name: `brochure_storage`,
          label: `Brochure`,
        },
        {
          name: `flyer_storage`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region SUBSTATION
  {
    name: `secure-numeric-network`,
    title: `Secured Digital Grid`,
    content: {
      columns: [
        [
          `In a context of maximising the use of telecommunication infrastructures, optimising control systems, obsolescence of communication network technologies, and increasing industrial cyber attacks, OMEXOM has developed the Secured Digital Grid platform to meet the challenges of today and tomorrow.`,
        ],
      ],
      files: [
        {
          name: `flyer_secure_digital_grid`,
          label: `Secured Digital Grid Flyer`,
        },
        {
          name: `flyer_faces`,
          label: `FACES Flyer`,
        },
        {
          name: `flyer_gds`,
          label: `GDS Flyer`,
        },
      ],
    }
  },
  {
    name: `transformation`,
    title: `Substation`,
    content: {
      columns: [
        [
          `The transformation of electricity – including air-insulated, gas-insulated and converter substations is a longstanding core business for Omexom and has been so for over 100 years. As an EPC contractor, Omexom delivers value-added solutions during the complete life-cycle of substations. Our expertise covers all steps of project development, from basic design, detailed design, procurement, construction, commissioning, maintenance and operation.`,
          `We have EPC references in HVDC Substations, AC Substations up to 500 kV in air insulated and up to 400 kV in gas insulated`,
        ],
      ],
      files: [
        {
          name: `brochure_substation`,
          label: `Brochure Substation`,
        },
      ],
    }
  },
  {
    name: `synchronous-condenser`,
    title: `Synchronous Condenser`,
    content: {
      columns: [
        [
          `A Synchronous condenser is an electrical rotating synchronous machine  connected to the electrical power Grid,`,
          `The machine is designed with a specific excitation system capable to absorb or deliver  reactive power to the Grid.`,
          `The Synchronous Condenser belongs to the family of FACTS products.`,
        ],
      ],
      files: [
        {
          name: `flyer_synchronous_condenser`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region TRANSMISSION
  {
    name: `buried-cables`,
    title: `Underground Cables`,
    content: {
      columns: [
        [
          `Electricity supply security is a key concern for every country. The wide-scale integration of intermittent energy sources into the electrical grid is a major challenge for operators around the world, both in terms of adapting existing infrastructures, and of developing smart systems to balance supply and consumption across the Grid.`,
        ],
        [
          `To meet these challenges, Omexom works with electricity transmission network operators and designs, builds and maintains high voltage lines throughout the world.`,
          `Omexom can manage and realize turnkey projects as an EPC contractor regardless of manufacturers technologies. This position confers a wide broad of expertise in accordance with clients’ requirements all over the world.`,
        ],
      ],
      files: [
        {
          name: `brochure_underground_cables`,
          label: `Brochure`,
        },
      ],
    }
  },
  {
    name: `asset-management`,
    title: `Asset Management`,
    content: {
      columns: [
        [
          `Omexom teams work on every step of energy’s assets life`,
          `We are a leading global contractor, from design to operation, inc. financing, building & maintenance with a strong innovative capacity!`,
        ],
      ],
      files: [
        {
          name: `brochure_asset_management`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `safe`,
    title: `SAFE`,
    content: {
      columns: [
        [
          `Structure audit & factual evaluation`,
          `Vibration analysis method to qualify the condition of structures and foundations of transmission towers, wind turbines and aerial mobility towers.`,
        ],
      ],
      files: [
        {
          name: `flyer_safe`,
          label: `Flyer SAFE`,
        },
      ],
    }
  },
  {
    name: `magnetic-rails`,
    title: `Magnetic Rails`,
    content: {
      columns: [
        [
          `Reflective magnetic rails are visibility marking on steel towers. Thanks to the reflective magnetic rails, which visibly mark steel towers (installed on any tower over 60 meters, or temporary towers over 15 meters), it is now possible to avoid flight accidents since these markers are visible from up to 1500 meters away from all flight angels at night or during the day.`,
        ],
      ],
      files: [
        {
          name: `brochure_magnetic_rails`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `temporary-tower`,
    title: `Temporary Tower Solution`,
    content: {
      columns: [
        [
          `Omexom Hochspannung GmbH has developed a modular system (Emergency Restoration System Towers) in which overhead transmission lines and substation construction can be erected very quickly and easily. This to restore the power supply as quickly as possible or to maintain it during the construction work in the event of weather-related faults or during maintenance or reconstruction works.`,
        ],
      ],
      files: [
        {
          name: `brochure_temporary_tower`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `drone-inspection`,
    title: `Drone Smart Inspection`,
    content: {
      columns: [
        [
          `Omexom develops global solutions in co-innovation with start-ups to facilitate inspection of power plants, power grids using drone automation, computer vision platform with artificial intelligence to detect defects (corrosion, partial discharges, …)  or manage vegetation in a fast and efficient way.`,
        ],
      ],
      files: [
        {
          name: `flyer_drone_smart_inspection`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `high-voltage-line`,
    title: `OHL Lines`,
    content: {
      columns: [
        [
          `Electricity supply security is a key concern for every country. The wide-scale integration of intermittent energy sources into the electrical grid is a major challenge for operators around the world, both in terms of adapting existing infrastructures, and of developing smart systems to balance supply and consumption across the Grid.`,
          `With its extensive knowledge of power transmission, Omexom, as a global EPC contractor, offers its customers a wide span of services across all the lifecycle of infrastructures. Thanks to collaboration with start-ups, Omexom also collects and applies all the best expertise around the world.`,
          `From design to commissioning and maintenance, Omexom supports its customers throughout every stage of a project.`,
        ],
      ],
      files: [
        {
          name: `brochure_overhead_lines`,
          label: `OHL Brochure`,
        },
      ],
    }
  },
  // #endregion TRANSMISSION
];
