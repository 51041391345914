// import * as THREE from 'three'
import Mats from './Mats'

export default class Sphere {
  constructor(ap3, assets) {
    this.init(ap3)
    this.setMats(assets.mats, assets.texs)
    this.deployGlb(assets.glbs)
  }

  init(ap3) {
    this.ap3 = ap3
  }

  setMats(mats, texs) {
    if (!this.matsSphere) {
      this.matsSphere = new Mats(mats, texs)
    } else {
      this.matsSphere.setMats(mats, texs)
    }
  }

  setNewSceneSphere(scene, assets) {
    this.setMats(assets.mats, assets.texs)
    this.setLocation(scene.name, scene.initialCamera)
  }

  deployGlb(glbs) {
    glbs.sphere.scene.traverse((o) => { this[o.name] = o })
    this.ap3.scene.add(this.fond)
  }

  setLocation(location, camRotation) {
    this.fond.material = this.matsSphere.mats[location]
    this.ap3.cam.iniCamRotation(camRotation)
  }

  disposeSceneAssets(scene) {
    this.matsSphere.disposeSceneMats(scene)
    this.fond.material = null
  }

  destroy() {
    this.ap3.scene.remove(this.fond)
    this.fond.material = null
    this.fond.geometry.dispose()
    for(let mat in this.matsSphere.mats) {
      this.matsSphere.mats[mat].map.dispose()
      this.matsSphere.mats[mat].dispose()
    }
  }
}