export default [
  // #region DISTRIBUTION
  {
    name: `distribution`,
    title: `Verteilung`,
    content: {
      columns: [
        [
          `Omexom verfügt über die nötigen Kompetenzen zur Abwicklung eines verständlichen Projektes, inklusive aller Phasen – Innovation, Machbarkeit, Gestaltung, Bau und Instandhaltung.`,
          `Die wesentlichen Herausforderungen bei den Angeboten von Omexom liegen in der Senkung des Gesamtkapitals und der operativen Ausgaben für die Verteilernetze und der gleichzeitigen Verbesserung von Schutz und Sicherheit und der Kohlenstoffbilanz des Netzes.`,
        ],
      ],
      files: [
        {
          name: `brochure_distribution`,
          label: `Broschüre`,
        },
      ],
    }
  },
  {
    name: `hotspot-phoenix`,
    title: `Phoenix`,
    content: {
      columns: [
        [
          `PHOENIX ist ein gerichteter Fehlerdetektor. Er erkennt elektrische Störungen auf 15 bis 20 kV-Leitungen und analysiert elektromagnetische Felder von Hochspannungsleitungen ohne jeglichen galvanischen Kontakt.`,
        ],
      ],
      files: [
        {
          name: `flyer_phoenix`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion DISTRIBUTION
  // #region PRODUCTION
  {
    name: `renewables-energies`,
    title: `Erneuerbare Energien`,
    content: {
      columns: [
        [
          `Omexom bietet Kunden in Europa und auf der ganzen Welt verständliche Lösungen an. Wir sind ein weltweit tätiges Netzwerk von Experten zur Stromerzeugung, die in der Lage sind, lokale Projekte anzubieten. Dank unserer Erfahrung im Bereich der gesamten Stromkette, von der Stromerzeugung bis zu dessen Verbrauch in den verschiedenen Gebieten, verstehen wir die Probleme und Herausforderungen unserer Kunden.`,
          `Wir profitieren von der lokalen Erfahrung unseres Netzwerks, das über die lokalen technischen Vorschriften bestens Bescheid weiß. Wir bieten mit unserem weltweiten Netzwerk aber auch globales Spezialwissen an.`,
        ],
        [
          `Wir können mit einem breiten Leistungsangebot, wie Projekt-Engineering, Beschaffung, Bau, Betrieb oder Instandhaltung aufwarten, um den Energie-Mix unserer Kunden zu dekarbonisieren.`,
          `Wir bieten Lösungen für eine nachhaltige und geringe Kohlenstoffbilanz, und für sichere und kostengünstige Kraftwerke an.`,
        ],
      ],
      files: [
        {
          name: `brochure_wind`,
          label: `Broschüre Wind`,
        },
        {
          name: `brochure_hydro`,
          label: `Broschüre Wasserkraftwerk`,
        },
        {
          name: `brochure_solar`,
          label: `Broschüre Solar`,
        },
      ],
    }
  },
  {
    name: `energy-storage`,
    title: `Hotspot Energiespeicherung`,
    content: {
      columns: [
        [
          `Energiespeicherung ist ein Hauptbestandteil jeder ganzheitlichen Betrachtung von intelligenten Stromnetzen, insbesondere dann, wenn sie Strom enthalten, der aus variablen, verteilten und erneuerbaren Energieressourcen stammt.`,
          `Der Umstieg auf sichere, nachhaltigere und flexiblere elektrische Netze profitiert dabei vom weit vebreiteten Einsatz von Energiespeichersystemen. Sie lassen sich durch ihre technische Gestaltung und Positionierung im Netz einfach anpassen und haben bewiesen, dass sie dazu fähig sind:`,
        ],
        [
          `1. die Integration von intermittierenden erneuerbaren Energiequellen zu erleichtern`,
          `2. den Verbrauch von fossilen Brennstoffen in Wärmekraftwerken durch Hybridisierung zu verringern`,
          `3. dem Netz effiziente Nebenleistungen, wie Frequenz- & Spannungssteuerung zur Verfügung zu stellen`,
          `4. Engpässe auszugleichen und den Investitionsbedarf hinsichtlich der Infrastruktur zurückzustellen`,
        ],
      ],
      files: [
        {
          name: `brochure_storage`,
          label: `Broschüre`,
        },
        {
          name: `flyer_storage`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region SUBSTATION
  {
    name: `secure-numeric-network`,
    title: `Secured Digital Grid`,
    content: {
      columns: [
        [
          `Im Zusammenhang mit der maximalen Nutzung von Telekommunikationsinfrastrukturen, der Optimierung von Steuersystemen, veralteter Kommunikationsnetzwerktechnik, und verstärkt auftretenden Cyber-Attacken in der Industrie hat OMEXOM die Plattform für sichere digitale Netze entwickelt, um die Herausforderungen von heute und von morgen annehmen zu können.`,
        ],
      ],
      files: [
        {
          name: `flyer_secure_digital_grid`,
          label: `Secured Digital Grid Flyer`,
        },
        {
          name: `flyer_faces`,
          label: `FACES Flyer`,
        },
        {
          name: `flyer_gds`,
          label: `GDS Flyer`,
        },
      ],
    }
  },
  {
    name: `transformation`,
    title: `Umwandlung`,
    content: {
      columns: [
        [
          `Die Umwandlung von Strom – inklusive luftisolierter, gasisolierter und Wandler-Umspannwerke gehört zu den langjährigen Kernkompetenzen von Omexom und dies bereits seit mehr als 100 Jahren. Als EPC-Unternehmen liefert Omexom Lösungen mit Wertsteigerung über die gesamte Lebensdauer der Umspannwerke hinweg. Unser Fachwissen deckt sämtliche Schritte der Projektentwicklung, von der Grundgestaltung, der detaillierten Planung, Beschaffung, Bau, Inbetriebnahme, Instandhaltung und Betrieb ab.`,
          `Wir verfügen über EPC Referenzen in HSGS-Umspannwerken, WS-Umspannwerken mit bis zu 500 kV im luftisolierten und bis zu 400 kV im gasisolierten Bereich`,
        ],
      ],
      files: [
        {
          name: `brochure_substation`,
          label: `Substation Broschüre`,
        },
      ],
    }
  },
  {
    name: `synchronous-condenser`,
    title: `Synchronous Condenser`,
    content: {
      columns: [
        [
          `Ein Synchrongenerator ist eine rotierende elektrische Synchronmaschine, die mit dem Stromnetz verbunden ist.`,
          `Die Maschine ist mit einem speziellen Anregungssystem ausgestattet, das imstande ist, Blindleistung zu absorbieren oder ins Stromnetz zu speisen.`,
          `Der Synchrongenerator gehört zur Gruppe der FACTS Produkte.`,
        ],
      ],
      files: [
        {
          name: `flyer_synchronous_condenser`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region TRANSMISSION
  {
    name: `buried-cables`,
    title: `Unterirdische Leitungen`,
    content: {
      columns: [
        [
          `Electricity supply security is a key concern for every country. The wide-scale integration of intermittent energy sources into the electrical grid is a major challenge for operators around the world, both in terms of adapting existing infrastructures, and of developing smart systems to balance supply and consumption across the Grid.`,
        ],
        [
          `Zur Bewältigung dieser Herausforderungen arbeitet Omexom mit Stromübertragungsnetzbetreibern zusammen und entwickelt, baut und wartet Hochspannungsleitungen auf der ganzen Welt.`,
          `Omexom kann schlüsselfertige Projekte als Generalunternehmer ungeachtet der Technologien der Hersteller managen und umsetzen. Diese Position verleiht uns ein breit angelegtes Fachwissen entsprechend den Kundenanforderungen auf der ganzen Welt.`,
        ],
      ],
      files: [
        {
          name: `brochure_underground_cables`,
          label: `Broschüre`,
        },
      ],
    }
  },
  {
    name: `asset-management`,
    title: `Betriebsmittelverwaltung`,
    content: {
      columns: [
        [
          `Die Teams von Omexom arbeiten an jedem Lebensschritt der verschiedenen Energie-Betriebsmittel`,
          `Wir sind ein führendes weltweit tätiges Unternehmen, von der Gestaltung bis zum Betrieb, inklusive Finanzierung, Bau & Instandhaltung mit ausgeprägter Fähigkeit zur Innovation!`,
        ],
      ],
      files: [
        {
          name: `brochure_asset_management`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `safe`,
    title: `SAFE`,
    content: {
      columns: [
        [
          `Structure audit & factual evaluation`,
          `Schwingungsanalyseverfahren zur Zustandseinschätzung von Strukturen und Fundamenten von Strommasten, Windkraftanlagen und Sendemasten.`,
        ],
      ],
      files: [
        {
          name: `flyer_safe`,
          label: `SAFE Flyer`,
        },
      ],
    }
  },
  {
    name: `magnetic-rails`,
    title: `Magnetic Rails`,
    content: {
      columns: [
        [
          `Reflektierende Magnetschienen sind sichtbare Zeichen von Stahlmasten. Dank dieser sichtbaren Zeichen von Stahlmasten, die alle Masten über 60 Meter und provisorischen Masten von mehr als 15 Metern kennzeichnen, ist es nun möglich, Flugunfälle durch das Sichtbarmachen von Masten aus bis zu 1.500 Metern Entfernung aus allen tatsächlichen Flugbahnen bei Tageslicht zu vermeiden.`,
        ],
      ],
      files: [
        {
          name: `brochure_magnetic_rails`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `temporary-tower`,
    title: `Temporary Tower Solution`,
    content: {
      columns: [
        [
          `Omexom Hochspannung GmbH hat ein modulares System (Notfall-Wiederherstellungsmast-System) entwickelt, bei dem der Bau von Freilandleitungen und Umspannwerken rasch und einfach über die Bühne gehen können. Dabei wird die Stromversorgung so rasch wie möglich wiederhergestellt oder während der Bauarbeiten bei witterungsbedingten Ausfällen oder während der Durchführung von Wartungsarbeiten aufrechterhalten.`,
        ],
      ],
      files: [
        {
          name: `brochure_temporary_tower`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `drone-inspection`,
    title: `Intelligente Dronen-Inspektion`,
    content: {
      columns: [
        [
          `Omexom entwickelt globale Lösungen durch gemeinsame Innovation mit Start-ups zur Erleichterung der Inspektion von Kraftwerken und Stromnetzen mit automatischen Drohnen, Computer-Visions-Plattformen mit künstlicher Intelligenz zum Erkennen von Defekten (Korrosion, Teilentladungen,…) oder zum Entfernen von Pflanzenwuchs auf rasche und wirksame Weise.`,
        ],
      ],
      files: [
        {
          name: `flyer_drone_smart_inspection`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `high-voltage-line`,
    title: `Freileitungen`,
    content: {
      columns: [
        [
          `Die gesicherte Stromversorgung ist jedem Land ein wesentliches Anliegen. Der hohe Integrationsgrad von intermittierenden Energiequellen in das Stromnetz ist eine besondere Herausforderung für die Anbieter auf der ganzen Welt, sowohl in Bezug auf die Anpassung bestehender Infrastrukturen, als auch auf die Entwicklung intelligenter Systeme zum Ausgleichen von Angebot und Nachfrage im Netz.`,
          `Durch das umfangreiche Wissen in Bezug auf Stromübertragung bietet Omexom seinen Kunden als weltweit tätiges EPC-Unternehmen ein umfassendes Leistungsangebot über den gesamten Lebenszyklus der Infrastrukturen hinweg an. Durch die Zusammenarbeit mit Start-ups sammelt Omexom auch die besten Erfahrungswerte rund um die Welt und wendet diese seinerseits an.`,
          `Von der Gestaltung bis zur Abnahme und Wartung unterstützt Omexom seine Kunden in allen erdenklichen Stufen des Projekts.`,
        ],
      ],
      files: [
        {
          name: `brochure_overhead_lines`,
          label: `Broschüre Freileitungen`,
        },
      ],
    }
  },
  // #endregion TRANSMISSION
];
