export default {
  name: `menu`,
  entries: [
    {
      name: `production`,
      label: `ERZEUGUNG`,
    },
    {
      name: `substation`,
      label: `UMWANDLUNG`,
    },
    {
      name: `transmission`,
      label: `ÜBERTRAGUNG`,
    },
    {
      name: `distribution`,
      label: `VERTEILUNG`,
    },
    {
      name: `website`,
      label: `Omexom.com`,
    },
    {
      name: `contact`,
      label: `KONTAKT`,
    },
    {
      name: `toggle_fullscreen`,
      label: `VOLLBILD`,
    },
    {
      name: `language_selection`,
      label: `SPRACHE`,
    },
  ]
}