<template>
  <div
    class="splash-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
    :style="`
      background-image: url(${config.image.backgroundImg});
    `"
  >
    <div class="splash-content">
      <div class="splash-screen-logo">
        <img :src="config.image.logo" alt="">
      </div>
      <div class="splash-screen-content-wrapper">
        <div class="splash-screen-base" :class="{ visible: visible && !langSelectorOpen }">
          <div class="splash-screen-welcome">
            {{ config.welcome }}
          </div>
          <div class="splash-screen-button" @click.prevent="openLangSelector">
            {{ config.langSelectorCTA }}
          </div>
          <div class="splash-screen-button" @click.prevent="startExperience">
            {{ config.startCTA }}
          </div>
        </div>
        <div class="splash-screen-lang-selector" :class="{ visible: visible && langSelectorOpen }">
          <div class="lang-buttons-wrapper">
            <div
              class="splash-screen-lang-button"
              v-for="(lang) in languages"
              :key="`${lang.key}-btn`"
              @click="langChosen(lang)"
            >
              <img :src="lang.icon" :alt="`${lang.label}-icon`" />
              <div class="language-label">{{ lang.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LanguageSelector from '@/components/LanguageSelector';

export default {
  // components: {
  //   LanguageSelector
  // },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    }
  },

  data: () => ({
    currentLanguage: null,
    langSelectorOpen: false,
  }),

  computed: {
    defaultLanguage() {
      return this.languages.find(lang => lang.isDefault === true);
    },
    startCTA() {
      return (this.currentLanguage ? this.currentLanguage.startCTA : this.defaultLanguage.startCTA);
    },
  },

  mounted() {
    const params = new URLSearchParams(window.location.search);
    const languageSelector = parseInt(params.get(`langSelect`));

    if (!isNaN(languageSelector) && languageSelector === 1) {
      this.langSelectorOpen = true;
    }
  },

  methods: {
    openLangSelector() {
      this.langSelectorOpen = true;
    },
    closeLangSelector() {
      this.langSelectorOpen = false;
    },
    startExperience() {
      this.$emit(`close`);
    },
    langChosen(language) {
      this.currentLanguage = language;
      this.$emit(`choose-lang`, language);
      this.startExperience();
    }
  },
};
</script>

<style lang="scss" scoped>
.splash-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;

  color: #ffffff;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .splash-content {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .splash-screen-logo {
      width: 60%;
      max-width: 400px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .splash-screen-content-wrapper {
      width: 100%;
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      position: relative;
      // background: tomato;

      .splash-screen-base,
      .splash-screen-lang-selector {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;

        &.visible {
          opacity: 1;
          pointer-events: all;
        }
      }

      .splash-screen-base {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .splash-screen-welcome {
          width: 90%;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 2em;
        }

        .splash-screen-button {
          width: 100%;
          max-width: 280px;
          padding: 1.5em 0;
          color: #082b69;
          border: 1px solid #ffffff;
          background-color: #ffffff;
          cursor: pointer;
          transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

          &:hover {
            color: #ffffff;
            background-color: #082b69;
          }
        }
      }

      .splash-screen-lang-selector {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .lang-buttons-wrapper {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .splash-screen-lang-button {
            width: calc(100% / 3 - 20px);
            display: flex;
            align-items: center;
            // justify-content: center;
            margin: 10px;
            padding: 1em 0;
            border: 1px solid #ffffff;
            background-color: #ffffff;
            color: #082b69;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

            img {
              width: 50px;
              margin: 0 20px;
            }

            &:hover {
              color: #ffffff;
              background-color: #082b69;
            }
          }
        }
      }
    }
  }


  @media all and (max-width: 1200px) {
    .splash-content {
      width: 75%;
    }
  }

  @media all and (max-width: 980px) {
    .splash-content {
      width: 90%;

      .splash-screen-content-wrapper {
        font-size: 0.8em;
        .splash-screen-base {
          .splash-screen-welcome {
            font-size: 1.1em;
            margin-bottom: 0;
          }
        }
      }

      .language-selector {
        width: 80%;
      }
    }
  }
}
</style>
