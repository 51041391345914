<template>
  <hotspot-ui
    class="omexom-hotspot"
    :class="{
      visited,
    }"
    :spot-config="spotConfig"
    :action="action"
    v-on="{ [action.type]: execAction }"
  >
    <div class="omexom-hotspot-inner">{{ category === `number` ? idx : `` }}</div>
  </hotspot-ui>
</template>

<script>
import HotspotUi from '@/components/HotspotUi/HotspotUi'

export default {
  components: {
    HotspotUi,
  },

  props: {
    spotConfig: {
      type: Object,
      required: true,
    },
    idx: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    visited: false,
  }),

  computed: {
    category() {
      return this.spotConfig?.category || `basic`;
    },
    action() {
      return this.spotConfig?.action || { type: ``, args: {} };
    },
    backgroundColor() {
      return this.spotConfig?.backgroundColor || '#ffffff';
    }
  },

  methods: {
    execAction() {

      this.$emit(`click`, { ...this.action, idx: this.idx });
    }
  },
}
</script>

<style lang="scss">
$border-width: 2px;

.omexom-hotspot {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  button {
    width: 40%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #082b69;
    border-radius: 100px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 2 * $border-width);
      height: calc(100% - 2 * $border-width);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
      border: $border-width solid #ffffff;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:focus {
      outline: none;
    }
  }

  &:hover {
    button::after {
      opacity: 1;
    }
  }
}

</style>