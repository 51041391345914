export default [
  {
    name: `global`,
    sphere: require(`@/assets/glbs/sphere.glb`),
    arrow: require(`@/assets/texs/arrow.png`),
    // arrow1: require(`@/assets/texs/arrow_01.png`),
    // arrow2: require(`@/assets/texs/arrow_02.png`),
    // arrow3: require(`@/assets/texs/arrow_03.png`),
  },
  // #region distribution
  {
    name: `distribution`,
    tex: require(`@/assets/360/distribution.jpg`),
    initialCamera: {
      lon: -36,
      azi: -2,
    },
    hotspots: {
      spots2D: [
        {
          name: `distribution`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `distribution`,
            },
          },
          longitude: -51.5,
          azimuth: 7.5,
        },
        {
          name: `hotspot-phoenix`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `hotspot-phoenix`,
            },
          },
          longitude: -51.5,
          azimuth: -25,
        },
      ],
    },
  },
  // #endregion distribution
  // #region production
  {
    name: `production`,
    tex: require(`@/assets/360/power.jpg`),
    initialCamera: {
      lon: 113.5,
      azi: -9,
    },
    hotspots: {
      spots2D: [
        {
          name: `renewables-energies`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `renewables-energies`,
            },
          },
          longitude: 115,
          azimuth: 11,
        },
        {
          name: `energy-storage`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `energy-storage`,
            },
          },
          longitude: 87,
          azimuth: -21,
        },
      ],
    },
  },
  // #endregion production
  // #region substation
  {
    name: `transformation_1`,
    tex: require(`@/assets/360/substation_synchro_condenser.jpg`),
    initialCamera: {
      lon: -109,
      azi: -8.5,
    },
    hotspots: {
      spots2D: [
        {
          name: `secure-numeric-network`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `secure-numeric-network`,
            },
          },
          longitude: -128,
          azimuth: -16,
        },
        {
          name: `transformation`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `transformation`,
            },
          },
          longitude: -109,
          azimuth: -8.5,
        },
        {
          name: `synchronous-condenser`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `synchronous-condenser`,
            },
          },
          longitude: -60,
          azimuth: -16,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_transformation_2',
          action: {
            type: `change-scene`,
            args: {
              scene: `transformation_2`,
            },
          },
          image: 'arrow',
          size: 1,
          longitude: -139,
          azimuth: -40,
          inclinationX: 48,
          inclinationY: -2,
          direction: 54,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `transformation_2`,
    tex: require(`@/assets/360/substation2.jpg`),
    initialCamera: {
      lon: 89,
      azi: -5,
    },
    hotspots: {
      spots2D: [
        {
          name: `transformation-spot-1`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `transformation`,
            },
          },
          longitude: 85.5,
          azimuth: 13,
        },
        {
          name: `transformation-spot-2`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `transformation`,
            },
          },
          longitude: 61,
          azimuth: 21,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_transformation_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `transformation_1`,
            },
          },
          image: 'arrow',
          size: 1,
          longitude: -84,
          azimuth: -10,
          inclinationX: 65,
          inclinationY: 0,
          direction: -4,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_transformation_3',
          action: {
            type: `change-scene`,
            args: {
              scene: `transformation_3`,
            },
          },
          image: 'arrow',
          size: 1,
          longitude: -18.5,
          azimuth: -4,
          inclinationX: 68,
          inclinationY: 0,
          direction: -7,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `transformation_3`,
    tex: require(`@/assets/360/substation1.jpg`),
    initialCamera: {
      lon: 93,
      azi: -2,
    },
    hotspots: {
      spots2D: [
        {
          name: `secure-numeric-network`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `secure-numeric-network`,
            },
          },
          longitude: 93,
          azimuth: -9,
        },
        {
          name: `transformation`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `transformation`,
            },
          },
          longitude: 78,
          azimuth: -8.5,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_transformation_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `transformation_1`,
            },
          },
          image: 'arrow',
          size: 1,
          longitude: 146,
          azimuth: -35,
          inclinationX: 50,
          inclinationY: 0,
          direction: -53,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
        {
          name: 'scene_change_transformation_2',
          action: {
            type: `change-scene`,
            args: {
              scene: `transformation_2`,
            },
          },
          image: 'arrow',
          size: 1,
          longitude: 87,
          azimuth: -43,
          inclinationX: 47,
          inclinationY: 0,
          direction: 7,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  // #endregion substation
  // #region transmission
  {
    name: `transport_1`,
    tex: require(`@/assets/360/transmissions1.jpg`),
    initialCamera: {
      lon: -83.3,
      azi: 15.5,
    },
    hotspots: {
      spots2D: [
        {
          name: `buried-cables`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `buried-cables`,
            },
          },
          longitude: -91,
          azimuth: -10,
        },
        {
          name: `asset-management`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `asset-management`,
            },
          },
          longitude: -93,
          azimuth: 9,
        },
        {
          name: `safe`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `safe`,
            },
          },
          longitude: -76,
          azimuth: 6,
        },
        {
          name: `magnetic-rails`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `magnetic-rails`,
            },
          },
          longitude: -95,
          azimuth: 37,
        },
        {
          name: `temporary-tower`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `temporary-tower`,
            },
          },
          longitude: -73,
          azimuth: 44,
        },
        {
          name: `drone-inspection`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `drone-inspection`,
            },
          },
          longitude: -100,
          azimuth: 63,
        },
        {
          name: `high-voltage-line`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `high-voltage-line`,
            },
          },
          longitude: -66,
          azimuth: 62,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_transport_2',
          action: {
            type: `change-scene`,
            args: {
              scene: `transport_2`,
            },
          },
          image: 'arrow',
          size: 1,
          longitude: -170,
          azimuth: -12,
          inclinationX: 59,
          inclinationY: 0,
          direction: 83,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  {
    name: `transport_2`,
    tex: require(`@/assets/360/transmissions2.jpg`),
    initialCamera: {
      lon: -77.5,
      azi: 7,
    },
    hotspots: {
      spots2D: [
        {
          name: `buried-cables`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `buried-cables`,
            },
          },
          longitude: -87,
          azimuth: -38,
        },
        {
          name: `asset-management`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `asset-management`,
            },
          },
          longitude: -90,
          azimuth: -20,
        },
        {
          name: `safe`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `safe`,
            },
          },
          longitude: -79.5,
          azimuth: -18,
        },
        {
          name: `magnetic-rails`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `magnetic-rails`,
            },
          },
          longitude: -91,
          azimuth: -1,
        },
        {
          name: `temporary-tower`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `temporary-tower`,
            },
          },
          longitude: -79,
          azimuth: 10,
        },
        {
          name: `drone-inspection`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `drone-inspection`,
            },
          },
          longitude: -73,
          azimuth: 33,
        },
        {
          name: `high-voltage-line`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `high-voltage-line`,
            },
          },
          longitude: -56,
          azimuth: 23,
        },
      ],
      spots3D: [
        {
          name: 'scene_change_transport_1',
          action: {
            type: `change-scene`,
            args: {
              scene: `transport_1`,
            },
          },
          image: 'arrow',
          size: 0.8,
          longitude: -63,
          azimuth: -41,
          inclinationX: 53,
          inclinationY: 5,
          direction: -38,
          clickShape: 'circle',
          clickScaleX: 1,
          clickScaleY: 1,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },
      ],
    },
  },
  // #endregion transmission
];