export default {
  name: `tutorial`,
  // tutorialElements: [
  //   {
  //     name: `360_movement`,
  //     video: require(`@/assets/tutorial/desktop/360_movement.mp4`),
  //   },
  //   {
  //     name: `arrows_movement`,
  //     video: require(`@/assets/tutorial/desktop/arrows_movement.mp4`),
  //   },
  //   {
  //     name: `hotspots`,
  //     video: require(`@/assets/tutorial/desktop/hotspots.mp4`),
  //   },
  // ],
}