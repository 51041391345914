export default {
  name: `map`,
  label: `CARTE`,
  spots: [
    {
      name: `distribution`,
      label: `DISTRIBUTION`
    },
    {
      name: `production`,
      label: `PRODUCTION`
    },
    {
      name: `transformation`,
      label: `TRANSFORMATION`
    },
    {
      name: `transport`,
      label: `TRANSPORT`
    },
  ],
}