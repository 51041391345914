export default {
  name: `map`,
  label: `MAP`,
  spots: [
    {
      name: `distribution`,
      label: `DISTRIBUTION`
    },
    {
      name: `production`,
      label: `POWER`
    },
    {
      name: `transformation`,
      label: `SUBSTATION`
    },
    {
      name: `transport`,
      label: `TRANSMISSION`
    },
  ],
}