import Vue from 'vue';
import App from './App.vue';
import breakpoints from './plugins/breakpoints';
import gtag from './plugins/gtag';

// $gtag plugin
Vue.use(gtag);

// $breakpoints plugin
Vue.use(breakpoints);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
