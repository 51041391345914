<template>
  <div
    class="spaces-video"
    :class="{ visible, mobile: $breakpoints.isMobile }"
    @click.prevent="close"
  >
    <div class="video-wrapper" @click.stop="noop">
      <video
        :class="{
          'video-visible': isVideoVisible,
        }"
        :src="video"
        ref="video"
        @ended="videoEnded"
        :muted="!hasSound || muted"
        :controls="controls"
        playsinline
        webkit-playsinline
        v-if="hasVideo"
      />
      <a
        class="close-button"
        :class="{
          visible: isCloseButtonVisible,
        }"
        @click.prevent="close"
      >
        ×
      </a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    video: {
      type: String,
      required: false,
    },
    controls: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    instance: null,
    isVideoVisible: false,
    isCloseButtonVisible: false,
  }),

  computed: {
    hasVideo() {
      return this.video;
    },
    hasSound() {
      return this.video?.hasSound || true;
    },
  },

  methods: {
    noop() {},
    close() {
      this.$emit(`close`);
      setTimeout(() => {
        this.isVideoVisible = false;
      }, 500);
    },
    videoEnded() {
      setTimeout(() => this.close(), 100);
    },
  },

  mounted() {
  },

  watch: {
    visible(visible) {
      if (visible) {
        setTimeout(() => {
          this.isVideoVisible = true;
        }, 500),
        setTimeout(() => {
          this.$refs[`video`].currentTime = 0;
          this.$refs[`video`].play();
          this.isCloseButtonVisible = true;
        }, 500);
      } else {
        setTimeout(() => {
          this.isVideoVisible = false;
          this.isCloseButtonVisible = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spaces-video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .video-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    video {
      max-width: 95vw;
      max-height: 95vh;
      object-fit: contain;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;

      &.video-visible {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: rgba(8, 43, 104, 0.7);
    font-size: 28px;
    line-height: 17px;
    transition: opacity 0.5s ease-in-out, color 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &:hover {
      color: rgba(8, 43, 104, 1);
    }

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
