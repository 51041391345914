
export default [
  {
    key: `fr_FR`,
    shortLabel: `FR`,
    label: `French`,
    icon: require(`@/assets/icons/flags/fr_FR.svg`),
    isDefault: true,
    startCTA: `COMMENCER`,
  },
  {
    key: `en_US`,
    shortLabel: `EN`,
    label: `English`,
    icon: require(`@/assets/icons/flags/en_US.svg`),
    isDefault: false,
    startCTA: `START`,
  },
  {
    key: `de_DE`,
    shortLabel: `DE`,
    label: `German`,
    icon: require(`@/assets/icons/flags/de_DE.svg`),
    isDefault: false,
    startCTA: `START`,
  },
  {
    key: `nb_NO`,
    shortLabel: `NO`,
    label: `Norwegian`,
    icon: require(`@/assets/icons/flags/nb_NO.svg`),
    isDefault: false,
    startCTA: `STARTE`,
  },
]
