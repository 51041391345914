export default [
  // #region DISTRIBUTION
  {
    name: `distribution`,
    title: `Utdeling av referansepunkt`,
    content: {
      columns: [
        [
          `Omexon her kunnskapen til å gi en allsidig prosjekt, som inneholder alle faser – fornying, gjennomføring, design, bygging og vedlikehold.`,
          `Redusere samlet kapital- og driftsutgifter til distribusjonsnettene samtidig som de forbedrer trygghet, sikkerheten og karbonavtrykk til nettet er hovedutfordringene som leder tilbudene til Omexom.`,
        ],
      ],
      files: [
        {
          name: `brochure_distribution`,
          label: `Brosjyre`,
        },
      ],
    }
  },
  {
    name: `hotspot-phoenix`,
    title: `Phoenix`,
    content: {
      columns: [
        [
          `PHOENIX is a directional fault detector. It detects electrical faults on 15 to 20 kV lines and analyses electromagnetic fields of distribution lines without any galvanic contact.`,
        ],
      ],
      files: [
        {
          name: `flyer_phoenix`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  // #endregion DISTRIBUTION
  // #region PRODUCTION
  {
    name: `renewables-energies`,
    title: `Referansepunkt fornybare energikilder`,
    content: {
      columns: [
        [
          `Omexom gir omfattende løsninger til kunder i Europa og i hele verden. Vi er et verdensomspennende nettverk av eksperter innen kraftproduksjon som har mulighet til å levere lokale prosjekter. Takket være vår ekspertise på hele den elektriske kjeden, fra kraftproduksjon til forbruk i territorier, forstår vi våre kunders problemer og utfordringer.`,
          `Vi har fordeler fra lokal ekspertise på nettverkene våre og som har en stor kunnskap på lokale tekniske forskrifter. Vi gir også global kunnskaps støtte med vårt verdensomspennende nettverk.`,
        ],
        [
          `Vi kan gi et bredt spekter av tjenester som prosjektering, anskaffelser, konstruering, administrering og vedlikehold til avkarbonisere våre kunders energisammensetning.`,
          `Vi gir løsninger for bærekraft av lavt karbonavtrykk, sikre og kostnadseffektiv kraftverk.`,
        ],
      ],
      files: [
        {
          name: `brochure_wind`,
          label: `Brosjyre for Vindkraft`,
        },
        {
          name: `brochure_hydro`,
          label: `Brosjyre for Vannkraft`,
        },
        {
          name: `brochure_solar`,
          label: `Brosjyre for Solenergi`,
        },
      ],
    }
  },
  {
    name: `energy-storage`,
    title: `Energilagring`,
    content: {
      columns: [
        [
          `Elektrisk lagring er et hovedkomponent til alle holistiske hensyn til smarte nett, spesielt når man fletter strøm som kommer fra forskjellige, distribuerte og fornybare energiressurser.`,
          `Overgangen til en tryggere, men bærekraftig og mer fleksibel elektrisk nettverk vil gi fordeler til det brede utrullingen av systemer for elektrisk energilagring Tilpasningsdyktig til alle situasjoner gjennom sin tekniske design og plassering er nettet, har de ev beviselig mulighet til å:`,
        ],
        [
          `1. Legge til rette for integrering av uregelmessige fornybare elektriske kilder`,
          `2. Minske forbruket av fossilt brennstoff av termiske planter gjennom hybridisering.`,
          `3. Gi effektiv hjelpetjenester til nettet, som frekvens og spenningskontroll`,
          `4. Frigjøre overbelastning og utsette behovet for å oppgradere av infrastrukturen`,
        ],
      ],
      files: [
        {
          name: `brochure_storage`,
          label: `Brosjyre`,
        },
        {
          name: `flyer_storage`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region SUBSTATION
  {
    name: `secure-numeric-network`,
    title: `Sikkert Digitalt Nett`,
    content: {
      columns: [
        [
          `I en kontekst til maksimering av bruk av telekommunikasjonens infrastruktur, optimaliserende kontrollsystemer, foreldede teknologier innen telekommunikasjonsnettverk og økende industrielle cyber angrep, har OMEXOM utviklet plattformen Sikkert Digitalt Nett for å komme utfordringene i dag og i morgen i møte.`,
        ],
      ],
      files: [
        {
          name: `flyer_secure_digital_grid`,
          label: `Flygeblad for Sikkert Digitalt Nett`,
        },
        {
          name: `flyer_faces`,
          label: `Flygeblad for FACES`,
        },
        {
          name: `flyer_gds`,
          label: `Flygeblad for GDS`,
        },
      ],
    }
  },
  {
    name: `transformation`,
    title: `Understasjon`,
    content: {
      columns: [
        [
          `Overføringen av strøm – inkludert luftisolerende, gassisolerende og konverterende understasjoner er en langvarig kjernevirksomhet for Omexom og har vært det i over 100 år. Som en EPC-kontaktor leverer Omexom verdiskapende løsninger i løpet av hele leve syklusen til understasjonene. Vår ekspertise dekker alle trinn i en prosjektutvikling, fra standard design, detaljert design, anskaffelse, konstruksjon, fullførelse, vedlikehold og drift.`,
          `Vi har EPC-referanser i HVDC understasjoner, AC understasjoner opp til 500 kv. i luftisolering og opp til 400 kv. i gassisolering.`,
        ],
      ],
      files: [
        {
          name: `brochure_substation`,
          label: `Brosjyre for Understasjon`,
        },
      ],
    }
  },
  {
    name: `synchronous-condenser`,
    title: `Synkron Kondensator`,
    content: {
      columns: [
        [
          `En synkron kondensator er en elektrisk roterende synkroniserende maskin som er koblet til det elektriske strømnettet,`,
          `Maskinen er laget med en bestemt spenningssystem som kan absorbere eller levere reaktiv effekt til nettet.`,
          `En synkron kondensator tilhører familien til FACTS-produktene.`,
        ],
      ],
      files: [
        {
          name: `flyer_synchronous_condenser`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region TRANSMISSION
  {
    name: `buried-cables`,
    title: `Referansepunkt for kabler under jorden`,
    content: {
      columns: [
        [
          `Sikkerhet for strømforsyning er hovedbekymringen for ethvert land. Integrering av uregelmessige energikilder i stor skala til elektrisk nett er en stor utfordring for driftsansvarlige rundt om i verden, begge med tanke på tilpassing til eksisterende infrastruktur, og ved utvikling av smartsystemer til å balansere tilbud og forbruk på tvers av nettet.`,
        ],
        [
          `For å møte disse utfordringene, arbeider Omexom med operatører og design av strømnettverket, oppbygging og vedlikehold av høyspentlinjer i hele verden.`,
          `Omexom kan administrere og realisere nøkkelferdige prosjekter som en EPC- (ingeniør-, anskaffelses- og anleggs) kontrakter uavhengig av produsenters teknologier. Denne posisjonen gir en stor ekspertise i samsvar med kunders krav i hele verden.`,
        ],
      ],
      files: [
        {
          name: `brochure_underground_cables`,
          label: `Brosjyre`,
        },
      ],
    }
  },
  {
    name: `asset-management`,
    title: `Kapitalforvaltning`,
    content: {
      columns: [
        [
          `Omexom-team arbeider på alle trinnene ved livet til energiens eiendeler.`,
          `Vi er en ledende global leverandør, fra design til drift, inkl. finansiering, bygging og vedlikehold med kraftig fornyende kapasitet!`,
        ],
      ],
      files: [
        {
          name: `brochure_asset_management`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  {
    name: `safe`,
    title: `SAFE`,
    content: {
      columns: [
        [
          `Struktur utregning og faktavurdering`,
          `Vibrerende analysemetoder for å kvalifisere forholdet til struktur og fundament av overføringstårn, vindturbiner og luftmobilitetstårn.`,
        ],
      ],
      files: [
        {
          name: `flyer_safe`,
          label: `Flygeblad for SAFE`,
        },
      ],
    }
  },
  {
    name: `magnetic-rails`,
    title: `Magnetiske rekkverk`,
    content: {
      columns: [
        [
          `Reflekterende magnetiske rekkverk er synlig markedsføring av ståltårn. Takket være dem, en synlig merking av ståltårn, merker alle tårn over 60 meter og midlertidige tårn over 15 meter, er det nå mulig å unngå flyulykker ved å merke tårn som synlige opp til 1500 meter avstand fra alle mulige flyretninger i dagslys.`,
        ],
      ],
      files: [
        {
          name: `brochure_magnetic_rails`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  {
    name: `temporary-tower`,
    title: `Midlertidige tårnløsninger`,
    content: {
      columns: [
        [
          `Omexom Hochspannung GmbH har utviklet et modulsystem (nød restaurering systemtårn) der luft overføringslinjer og konstruksjon av transformatorstasjon kan føres opp meget raskt og enkelt. Dette for å føre opp stømforsyning så raskt som mulig eller for å beholde det under arbeid med oppføring i tilfelle vær-relaterte problemer eller under vedlikehold eller rekonstruksjonsarbeide.`,
        ],
      ],
      files: [
        {
          name: `brochure_temporary_tower`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  {
    name: `drone-inspection`,
    title: `Drone Smart Undersøkelse`,
    content: {
      columns: [
        [
          `Omexom utvikler globale løsninger i samarbeid med oppstart lag for å legge til rette undersøkelser av kraftverk, strømnett ved å bruke automatikk-droner, plattformer med datamaskin syn med kunstig intelligens for å oppdage defekter (rust, delvis utladning, ...) eller administrere vegeterende tilværelse i en rask og effektiv måte.`,
        ],
      ],
      files: [
        {
          name: `flyer_drone_smart_inspection`,
          label: `Flygeblad`,
        },
      ],
    }
  },
  {
    name: `high-voltage-line`,
    title: `OHL linjer`,
    content: {
      columns: [
        [
          `Sikkerhet for strømforsyning er hovedbekymringen for ethvert land. Integrering av uregelmessige energikilder i stor skala til elektrisk nett er en stor utfordring for driftsansvarlige rundt om i verden, begge med tanke på tilpassing til eksisterende infrastruktur, og ved utvikling av smartsystemer til å balansere tilbud og forbruk på tvers av nettet.`,
          `Dette er omfattende kunnskap til kraftoverføring, er Omexom en global EPC-kontrakter, og tilbyr sine kunder er bredt utvalg tjenester på tvers av levetiden til infrastrukturen. Takket være samarbeid med oppstartslag, samler og anvender Omexom også all den beste ekspertisen rundt om i verden.`,
          `Fra design til ferdigstillelse, hjelper Omexom sine kunder gjennom hvert eneste trinn i prosjektet.`,
        ],
      ],
      files: [
        {
          name: `brochure_overhead_lines`,
          label: `Brosjyre for OHL`,
        },
      ],
    }
  },
  // #endregion TRANSMISSION
];
