export default [
  // #region DISTRIBUTION
  {
    name: `distribution`,
    title: `Distribution`,
    content: {
      columns: [
        [
          `Omexom dispose des compétences nécessaires pour mener à bien un projet complet, englobant toutes les phases - innovation, étude de faisabilité, conception, construction et maintenance.`,
        ],
      ],
      files: [
        {
          name: `brochure_distribution`,
          label: `Brochure`,
        },
      ],
    }
  },
  {
    name: `hotspot-phoenix`,
    title: `Phoenix`,
    content: {
      columns: [
        [
          `PHOENIX est un détecteur de défaut directionnel. Il détecte les défauts électriques sur les lignes de 15 à 20 kV et analyse les champs électromagnétiques des lignes de distribution sans aucun contact électrique.`,
        ],
      ],
      files: [
        {
          name: `flyer_phoenix`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion DISTRIBUTION
  // #region PRODUCTION
  {
    name: `renewables-energies`,
    title: `Énergies renouvelables`,
    content: {
      columns: [
        [
          `Omexom fournit des solutions complètes à ses clients en Europe et dans le monde entier. Nous sommes un réseau mondial d'experts de la production d'energie qui a la capacité de réaliser des projets locaux. Grâce à notre expertise sur l'ensemble de la chaîne de valeur de l'énergie, de la production d'électricité à la consommation sur les territoires, nous comprenons les enjeux et les défis de nos clients.`,
          `Nous bénéficions de l'expertise locale de notre réseau, qui a une connaissance approfondie des réglementations techniques locales. Nous fournissons également un support de connaissances global grâce à notre réseau mondial.`,
        ],
        [
          `Nous pouvons fournir une large gamme de services tels que l'ingénierie de projet, l'approvisionnement, la construction, l'exploitation ou la maintenance pour décarboniser le mix énergétique de nos clients.`,
          `Nous fournissons des solutions pour des centrales électriques durables et à faible empreinte carbone, sûres et rentables.`,
        ],
      ],
      files: [
        {
          name: `brochure_wind`,
          label: `Brochure Eolien`,
        },
        {
          name: `brochure_hydro`,
          label: `Brochure Hydro`,
        },
        {
          name: `brochure_solar`,
          label: `Brochure Solaire`,
        },
      ],
    }
  },
  {
    name: `energy-storage`,
    title: `Stockage d'énergie`,
    content: {
      columns: [
        [
          `Le stockage d'énergie est un élément essentiel de toute réflexion globale sur les réseaux intelligents, en particulier lorsqu'il s'agit d'intégrer l'énergie provenant de ressources énergétiques variables, distribuées et renouvelables.`,
          `La transition vers des réseaux électriques plus sûrs, plus durables et plus flexibles bénéficiera du déploiement généralisé des systèmes de stockage de l'énergie. Adaptables à tout type de situation grâce à leur conception technique et à leur emplacement sur le réseau, ils ont prouvé leur capacité à :`,
        ],
        [
          `1. Faciliter l'intégration des sources d'énergie renouvelables intermittentes.`,
          `2. Réduire la consommation de combustibles fossiles des centrales thermiques par l'hybridation.`,
          `3. Fournir des services auxiliaires efficaces au réseau, tels que le contrôle de la fréquence et de la tension.`,
          `4. Réduire les congestions et reporter la nécessité de moderniser les infrastructures.`,
        ],
      ],
      files: [
        {
          name: `brochure_storage`,
          label: `Brochure`,
        },
        {
          name: `flyer_storage`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region SUBSTATION
  {
    name: `secure-numeric-network`,
    title: `Réseau numérique sécurisé`,
    content: {
      columns: [
        [
          `Dans un contexte de maximisation de l'utilisation des infrastructures de télécommunication, d'optimisation des systèmes de contrôle, d'obsolescence des technologies des réseaux de communication et de multiplication des cyberattaques industrielles, OMEXOM a développé la plateforme Secured Digital Grid pour répondre aux défis d'aujourd'hui et de demain.`,
        ],
      ],
      files: [
        {
          name: `flyer_secure_digital_grid`,
          label: `Flyer Secured Digital Grid`,
        },
        {
          name: `flyer_faces`,
          label: `Flyer FACES`,
        },
        {
          name: `flyer_gds`,
          label: `Flyer GDS`,
        },
      ],
    }
  },
  {
    name: `transformation`,
    title: `Transformation`,
    content: {
      columns: [
        [
          `La transformation d'énergie - y compris les sous-stations isolées par air, isolées par gaz et les convertisseurs - est une activité de base de longue date pour Omexom, et ce depuis plus de 100 ans. En tant qu'entrepreneur EPC, Omexom fournit des solutions à valeur ajoutée pendant le cycle de vie complet des sous-stations. Notre expertise couvre toutes les étapes du développement d'un projet, depuis la conception de base, la conception détaillée, l'approvisionnement, la construction, la mise en service, la maintenance et l'exploitation.`,
          `Nous avons des références EPC dans les sous-stations HVDC, les sous-stations AC jusqu'à 500 kV en isolation air et jusqu'à 400 kV en isolation gaz.`,
        ],
      ],
      files: [
        {
          name: `brochure_substation`,
          label: `Brochure Transformation`,
        },
      ],
    }
  },
  {
    name: `synchronous-condenser`,
    title: `Condensateur synchrone`,
    content: {
      columns: [
        [
          `Un condensateur synchrone est une machine électrique synchrone rotative connectée au réseau électrique.`,
          `La machine est conçue avec un système d'excitation spécifique capable d'absorber ou de délivrer de la puissance réactive au Réseau.`,
          `Le condensateur synchrone appartient à la famille des produits FACTS.`,
        ],
      ],
      files: [
        {
          name: `flyer_synchronous_condenser`,
          label: `Flyer`,
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region TRANSMISSION
  {
    name: `buried-cables`,
    title: `Cables entêrrés`,
    content: {
      columns: [
        [
          `La sécurité de l'approvisionnement en électricité est une préoccupation majeure pour tous les pays. L'intégration à grande échelle de sources d'énergie intermittentes dans le réseau électrique est un défi majeur pour les opérateurs du monde entier, tant en termes d'adaptation des infrastructures existantes, que de développement de systèmes intelligents pour équilibrer l'offre et la consommation à travers le réseau.`,
        ],
        [
          `Pour relever ces défis, Omexom travaille avec les opérateurs de réseaux de transport d'énergie et conçoit, construit et entretient des lignes à haute tension dans le monde entier.`,
          `Omexom peut gérer et réaliser des projets clés en main en tant qu'entrepreneur EPC, quelles que soient les technologies des fabricants. Cette position lui confère un large éventail d'expertise en accord avec les exigences des clients dans le monde entier.`,
        ],
      ],
      files: [
        {
          name: `brochure_underground_cables`,
          label: `Brochure`,
        },
      ],
    }
  },
  {
    name: `asset-management`,
    title: `Asset Management`,
    content: {
      columns: [
        [
          `Les équipes d'Omexom travaillent à chaque étape de la vie des actifs énergétiques.`,
          `Nous sommes un entrepreneur mondial de premier plan, de la conception à l'exploitation, en passant par le financement, la construction et la maintenance, avec une forte capacité d'innovation !`,
        ],
      ],
      files: [
        {
          name: `brochure_asset_management`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `safe`,
    title: `SAFE`,
    content: {
      columns: [
        [
          `Structure audit & factual evaluation`,
          `Méthode d'analyse vibratoire pour qualifier l'état des structures et des fondations des tours de transmission, des éoliennes et des tours de mobilité aérienne.`,
        ],
      ],
      files: [
        {
          name: `flyer_safe`,
          label: `Flyer SAFE`,
        },
      ],
    }
  },
  {
    name: `magnetic-rails`,
    title: `Rails magnétiques`,
    content: {
      columns: [
        [
          `Les rails magnétiques réfléchissants permettent de marquer et de repérer les pylônes. Grâce à cela, il est maintenant possible d'éviter les accidents de vol en rendant les pylônes visibles jusqu'à 1500 mètres de distance depuis tous les angles de vol en plein jour. Cela fonctionne pour les pylônes en acier,  les pylônes de plus de 60 mètres et les pylônes temporaires de plus de 15 mètres.`,
        ],
      ],
      files: [
        {
          name: `brochure_magnetic_rails`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `temporary-tower`,
    title: `Tour temporaire`,
    content: {
      columns: [
        [
          `Omexom Hochspannung GmbH a mis au point un système modulaire (Emergency Restoration System Towers) permettant d'ériger très rapidement et facilement des lignes aériennes de transmission et des sous-stations. Ceci afin de rétablir l'alimentation électrique le plus rapidement possible ou de la maintenir pendant les travaux de construction en cas de défaillances dues aux intempéries ou pendant les travaux de maintenance ou de reconstruction.`,
        ],
      ],
      files: [
        {
          name: `brochure_temporary_tower`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `drone-inspection`,
    title: `Inspection par drône intelligent`,
    content: {
      columns: [
        [
          `Omexom développe des solutions globales en co-innovation avec des start-ups pour faciliter l'inspection des centrales électriques, des réseaux électriques en utilisant l'automatisation des drones, la plateforme de vision par ordinateur avec intelligence artificielle pour détecter les défauts (corrosion, décharges partielles, ...) ou gérer la végétation de manière rapide et efficace.`,
        ],
      ],
      files: [
        {
          name: `flyer_drone_smart_inspection`,
          label: `Flyer`,
        },
      ],
    }
  },
  {
    name: `high-voltage-line`,
    title: `Ligne haute tension`,
    content: {
      columns: [
        [
          `La sécurité de l'approvisionnement en électricité est une préoccupation majeure pour tous les pays. L'intégration à grande échelle de sources d'énergie intermittentes dans le réseau électrique est un défi majeur pour les opérateurs du monde entier, tant en termes d'adaptation des infrastructures existantes que de développement de systèmes intelligents pour équilibrer l'offre et la consommation sur le réseau.`,
          `Grâce à sa connaissance approfondie du transport d'électricité, Omexom, en tant qu'entrepreneur EPC mondial, offre à ses clients un large éventail de services tout au long du cycle de vie des infrastructures. Grâce à sa collaboration avec des start-ups, Omexom recueille et applique également toutes les meilleures expertises à travers le monde.`,
          `De la conception à la mise en service et à la maintenance, Omexom accompagne ses clients à chaque étape d'un projet.`,
        ],
      ],
      files: [
        {
          name: `brochure_overhead_lines`,
          label: `Brochure Ligne haute tension`,
        },
      ],
    }
  },
  // #endregion TRANSMISSION
];
