<template>
  <div
    class="omexom-menu-wrapper"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="omexom-menu-base" @click="toggleMenu">
      <div class="icon-wrapper">
        <div class="menu-icon menu-base-icon" :class="{visible: !open}" v-html="menu.iconClosed"/>
        <div class="menu-icon menu-base-icon" :class="{visible: open}" v-html="menu.iconOpen"/>
      </div>
      <div class="omexom-logo-wrapper">
        <img :src="menu.logo" alt="omexom-logo" />
      </div>
    </div>
    <div class="omexom-menu-content-wrapper">
      <div class="omexom-menu" :class="{ open }">
        <div
          class="omexom-menu-entry"
          @click="handleEntryClick(entry)"
          v-for="(entry, idx) in menu.entries"
          :key="`${entry.name}-${idx}`"
        >
          <div class="number-icon-wrapper">
            <div class="number" v-if="(typeof entry.number !== 'undefined')">{{ entry.number }}</div>
            <div class="menu-icon" v-else-if="(typeof entry.icon !== 'undefined')" v-html="entry.icon"/>
          </div>
          <div class="menu-entry-label">
            {{ entry.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    menu: {
      type: Object,
      required: true,
    }
  },

  computed: {
    menuButtons() {
      return this.menu?.entries || []
    },
  },

  methods: {
    toggleMenu() {
      this.$emit(`toggle-menu`);
    },
    handleEntryClick(entry) {
      switch (entry.action) {
        case `change-scene`:
          this.$emit(`change-scene`, entry.scene);
          break;
        case `open-link`:
          window.open(entry.link, '_blank').focus();
          break;
        case `toggle-fullscreen`:
          this.toggleFullscreen();
          break;
        case `open-language-selector`:
          window.location.href = `${window.location.origin}?langSelect=1`;
          break;
      }
    },
    buttonAction(action) {
      switch (action) {
        case `open-map`:
          this.openMap();
          break;
        case `toggle-fullscreen`:
          this.toggleFullscreen();
          break;
      }
    },
    openMap() {
      this.$emit(`open-map`);
    },
    toggleFullscreen() {
      this.$emit(`toggle-fullscreen`);
    },
  },
};
</script>

<style lang="scss">
.omexom-menu-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  .omexom-menu-base {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
    background-color: #082b69;
    cursor: pointer;

    .icon-wrapper {
      width: 2em;
      height: 2em;
      position: relative;
    }

    .omexom-logo-wrapper {
      width: 75%;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  .omexom-menu-content-wrapper {
    width: 100%;
    overflow: clip hidden;
    position: relative;

    .omexom-menu {
      width: 100%;
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: translateY(0);
      }

      .omexom-menu-entry {
        cursor: pointer;
        padding: 0.5em 32px;
        border-top: 1px solid #ffffff;
        background-color: rgba(8, 43, 104, 0.95);
        display: flex;
        align-items: center;
        font-size: 1.3em;
        font-weight: 500;
        color: #ffffff;

        .number-icon-wrapper {
          width: 2em;
          height: 2em;
          margin-right: 1em;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.95);
          color: #082b69;
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    .omexom-menu-content-wrapper
    .omexom-menu
    .omexom-menu-entry {
      font-size: 1em;
    }
  }
}
</style>

<style lang="scss">
.menu-icon {
  width:  70%;
  height: 70%;

  svg {
    height: 100%;
    width: 100%;

    &, path {
      fill: #ffffff;

    }

    path {
      transition: fill 0.3s ease-in-out;
    }
  }
}


.menu-base-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.omexom-menu-entry:hover {
  .menu-icon {
    svg, path {
      fill: #082b69;
    }
  }

}
</style>