export default {
  name: `loading`,
  backgroundImg: require(`@/assets/splashscreen/splashscreen_background.jpg`),
  logo: require(`@/assets/logos/logo_white.png`),
  tutorialParts: [
    {
      name: `dragTuto`,
      icon: require(`@/assets/tutorial/dragTuto.png`),
    },
    {
      name: `moveTuto`,
      icon: require(`@/assets/tutorial/moveTuto.png`),
    },
    {
      name: `spotTuto`,
      icon: require(`@/assets/tutorial/spotTuto.png`),
    },
    {
      name: `menuTuto`,
      icon: require(`@/assets/tutorial/menuTuto.png`),
    },
  ]
}