<template>
  <div id="spaces-visit" :class="{ visible }">
    <spaces-scene
      :scene="currentScene"
      :scenes-config="scenes"
      :muted="muted || isSceneMuted"
      :gyro-enabled="gyroEnabled"
      :render="render"
      :gyro-datas="gyroDatas"
      :dialog-open="isDialogOpened"
      @scene-mounted="sceneMounted"
      @change-scene="changeScene"
      @open-dialog="openDialog"
      @close-dialog="closeAllDialogs"
      ref="spacesScene"
      v-if="currentScene"
    />
    <spaces-video
      :video="currentSpacesVideo"
      :visible="isSpacesVideoVisible"
      :controls="true"
      :muted="muted"
      @close="closeSpacesVideo"
    />
    <omexom-menu
      :open="isMenuOpened"
      :menu="menu"
      @toggle-menu="toggleMenu"
      @change-scene="changeSceneFromParent"
      @toggle-fullscreen="toggleFullscreen"
    />
    <omexom-map
      :open="isMapOpened"
      :map="map"
      @open-map="openMap"
      @close="closeMap"
      @close-menu="closeMenu"
      @change-scene="changeSceneFromParent"
    />
    <omexom-dialog
      :visible="isDialogOpened"
      :name="currentDialogName"
      :dialog="currentDialog"
      :icons="visit.icons"
      @open-video="openSpacesVideo"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import SpacesScene from '@/components/SpacesScene';
import SpacesVideo from '@/components/SpacesVideo';
import OmexomMenu from '@/components/OmexomMenu';
import OmexomDialog from '@/components/OmexomDialog';
import OmexomMap from '@/components/OmexomMap';

export default {
  components: {
    SpacesScene,
    SpacesVideo,
    OmexomMenu,
    OmexomDialog,
    OmexomMap,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    visit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    currentExperience: {
      type: String,
      required: true,
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    isMenuOpened: false,
    currentDialogName: `distribution`,
    isDialogOpened: false,
    isMapOpened: true,
    hasFirstSceneLoaded: false,
    previousDialogName: null,
    currentSpacesVideo: null,
    isSpacesVideoVisible: false,
    firstSceneName: `distribution`,
    isChangingScene: false,
    previousSceneName: null,
    currentSceneName: null,
    isSceneVisible: false,
    isSceneTicking: false,
    areHotspotsVisible: false,
    isSceneMuted: false,
  }),

  computed: {
    scenes() {
      return this.visit?.scenes || [];
    },
    dialogs() {
      return this.visit?.dialogs || [];
    },
    map() {
      return this.visit?.map || {};
    },
    menu() {
      return this.visit?.menu || {};
    },
    tutorials() {
      return this.visit?.tutorials || [];
    },
    currentScene() {
      let currentScene = null;
      if (this.currentSceneName) {
        currentScene = this.getSceneByName(this.currentSceneName);
      }
      return currentScene || null;
    },
    previousScene() {
      let previousScene = null;
      if (this.previousSceneName) {
        previousScene = this.getSceneByName(this.previousSceneName);
      }

      return previousScene || null;
    },
    currentDialog() {
      return this.dialogs.find(dialog => dialog.name === this.currentDialogName) || {};
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened;
    },
    closeMenu() {
      this.isMenuOpened = false;
    },
    toggleFullscreen() {
      this.$emit(`toggle-fullscreen`);
    },
    getDialogByName(dialogName) {
      return this.dialogs.find(dialog => dialog.name === dialogName);
    },
    getSceneByName(sceneName) {
      return this.scenes.find(scene => scene.name === sceneName);
    },
    getTutorialByName(tutorialName) {
      return this.tutorials.find(tuto => tuto.name === tutorialName);
    },
    openSpacesVideo(video) {
      this.currentSpacesVideo = video;
      this.isSpacesVideoVisible = true;
    },
    async closeSpacesVideo() {
      this.isSpacesVideoVisible = false;
      await this.wait(500);
      this.currentSpacesVideo = null;
    },
    resetZoom() {
      document.body.style.transform = `scale(1)`;
      document.body.style.msTransform = `scale(1)`;
    },
    pauseSound() {
      this.$emit(`pause-sound`);
    },
    resumeSound() {
      this.$emit(`resume-sound`);
    },
    toggleTutorial() {
      this.isTutorialOpened = !this.isTutorialOpened;
    },
    resumeSoundAfterDialogClose() {
      if (!this.currentScene.hasSound) {
        this.resumeSound();
      }
    },
    async startOverExperience() {
      this.changeSceneFromParent(`place_vendome_1`);
    },
    changeSceneFromParent(scene) {
      this.closeMenu();
      if (scene === this.currentSceneName) {
        this.closeMap();
      } else {
        this.$refs.spacesScene.changeSceneFromParent(scene);
      }
    },
    async changeScene(sceneName) {
      if (this.isChangingScene || this.currentSceneName === sceneName) { return; }

      this.isChangingScene = true;

      await this.wait(100);
      this.currentSceneName = sceneName;
    },
    async sceneMounted() {
      this.isChangingScene = false;
      await this.wait();
      this.isSceneTicking = true;
      this.isSceneVisible = true;
      this.areHotspotsVisible = true;
      if (this.hasFirstSceneLoaded) {
        this.closeMap();
        this.closeMenu();
      }
      this.hasFirstSceneLoaded = true;
    },
    openHub() {
      this.changeScene(`hub`);
    },
    openMap() {
      this.closeDialog();
      this.isMapOpened = true;
    },
    closeMap() {
      this.isMapOpened = false;
    },
    goHome() {
      this.closeMap();
      if (this.currentSceneName !== this.firstSceneName) {
        this.changeScene(this.firstSceneName);
      }
    },
    openDialog(dialogName) {
      this.currentDialogName = dialogName;
      this.isDialogOpened = true;
    },
    async closeDialog() {
      this.isDialogOpened = false;
      await this.wait(500);
      this.currentDialogName = ``;
    },
    closeAllDialogs() {
      this.closeDialog();
    }
  },

  mounted() {
    setTimeout(() => {
      this.changeScene(this.firstSceneName);
      if (!this.currentDialog) {
        this.currentDialog = this.dialogs[0];
      }
    });
  },

  watch: {
    currentExperience(newExp) {
      this.changeScene(`${newExp}`);
    },
  },
};
</script>

<style lang="scss" scoped>
#position-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: tomato;
}

.spaces-visit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lose-message {
  width: fit-content;
  position: absolute;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;

  &.visible {
    opacity: 1;
  }
}

.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
