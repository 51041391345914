<template>
  <div class="intro-video" :class="{ visible }" @click="close">
    <div class="video-wrapper" @click.stop="noop">
      <video
        :src="video.src"
        ref="video"
        @ended="videoEnded"
        playsinline
        webkit-playsinline
        v-if="hasVideo && isVideoVisible"
      />
    </div>
    <a class="close-button" @click.prevent="close">
      {{ video.exitButton }}
    </a>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    video: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    instance: null,
    isVideoVisible: false,
  }),

  computed: {
    hasVideo() {
      return this.video && this.video.src;
    },
    canSkip() {
      return true;
    }
  },

  methods: {
    noop() {},
    close() {
      this.$emit(`close`);
      setTimeout(() => {
        this.isVideoVisible = false;
      }, 500);
    },
    videoEnded() {
      setTimeout(() => this.close(), 100);
    },
  },

  mounted() {
    this.isVideoVisible = true;
  },

  watch: {
    visible(visible) {
      if (visible) {
        setTimeout(() => {
          if (!visible) {
            this.isVideoVisible = false;
          }
          this.$refs[`video`].currentTime = 0;
          this.$refs[`video`].play();
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .video-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    video {
      width: 100vw;
      height: 100vh;
      object-fit: contain;
    }
  }
  .close-button {
    position: fixed;
    bottom: 7%;
    right: 5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 13px;
    padding: 0.75em 2.5em;
    border-radius: 3em;
    background-color: rgba(255, 255, 255, 0.6);
    color: #4bb8d9;
    font-weight: 500;
    border: 1px solid #4bb8d9;

    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
      color: #ffffff;
      background-color: #4bb8d9;
    }
    img {
      height: 50px;
    }
  }
}
</style>
