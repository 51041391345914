export default [
  // #region DISTRIBUTION
  {
    name: `distribution`,
    img: require(`@/assets/dialogs/images/distribution.png`),
    video: require(`@/assets/videos/distribution.mp4`),
    content: {
      files: [
        {
          name: `brochure_distribution`,
          file: require(`@/assets/dialogs/documents/brochure_distribution.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_distribution.png`),
        },
      ],
    }
  },
  {
    name: `hotspot-phoenix`,
    img: require(`@/assets/dialogs/images/phoenix.png`),
    video: require(`@/assets/videos/phoenix.mp4`),
    content: {
      files: [
        {
          name: `flyer_phoenix`,
          file: require(`@/assets/dialogs/documents/flyer_phoenix.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_phoenix.png`),
        },
      ],
    }
  },
  // #endregion DISTRIBUTION
  // #region PRODUCTION
  {
    name: `renewables-energies`,
    img: require(`@/assets/dialogs/images/renewables_energies.png`),
    video: require(`@/assets/videos/renewables_energies.mp4`),
    content: {
      files: [
        {
          name: `brochure_wind`,
          file:  require(`@/assets/dialogs/documents/brochure_power_wind.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_power_wind.png`),
        },
        {
          name: `brochure_hydro`,
          file:  require(`@/assets/dialogs/documents/brochure_power_hydro.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_power_hydro.png`),
        },
        {
          name: `brochure_solar`,
          file:  require(`@/assets/dialogs/documents/brochure_power_solar.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_power_solar.png`),
        },
      ],
    }
  },
  {
    name: `energy-storage`,
    img: require(`@/assets/dialogs/images/energy_storage.jpg`),
    video: require(`@/assets/videos/energy_storage.mp4`),
    content: {
      files: [
        {
          name: `brochure_storage`,
          file:  require(`@/assets/dialogs/documents/brochure_storage.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_battery_energy_storage_system.png`), // TMP
        },
        {
          name: `flyer_storage`,
          file:  require(`@/assets/dialogs/documents/flyer_battery_energy_storage_system.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_battery_energy_storage_system.png`),
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region SUBSTATION
  {
    name: `secure-numeric-network`,
    img: require(`@/assets/dialogs/images/secured_digital_grid.jpg`),
    video: require(`@/assets/videos/secure_digital_grid.mp4`),
    content: {
      files: [
        {
          name: `flyer_secure_digital_grid`,
          file:  require(`@/assets/dialogs/documents/flyer_secured_digital_grid.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_secured_digital_grid.png`),
        },
        {
          name: `flyer_faces`,
          file:  require(`@/assets/dialogs/documents/flyer_faces.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_faces.png`),
        },
        {
          name: `flyer_gds`,
          file:  require(`@/assets/dialogs/documents/flyer_gds.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_gds.png`),
        },
      ],
    }
  },
  {
    name: `transformation`,
    img: require(`@/assets/dialogs/images/transformation.png`),
    video: require(`@/assets/videos/transformation.mp4`),
    content: {
      files: [
        {
          name: `brochure_substation`,
          file:  require(`@/assets/dialogs/documents/brochure_substation.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/substation_transmission_underground_cables.png`),
        },
      ],
    }
  },
  {
    name: `synchronous-condenser`,
    img: require(`@/assets/dialogs/images/synchronous_condenser.jpg`),
    video: require(`@/assets/videos/synchronous_condenser.mp4`),
    content: {
      files: [
        {
          name: `flyer_synchronous_condenser`,
          file:  require(`@/assets/dialogs/documents/flyer_synchronous_condenser.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_synchronous_condenser.png`),
        },
      ],
    }
  },
  // #endregion PRODUCTION
  // #region TRANSMISSION
  {
    name: `buried-cables`,
    img: require(`@/assets/dialogs/images/underground_cables.jpg`),
    video: require(`@/assets/videos/underground_cables.mp4`),
    content: {
      files: [
        {
          name: `brochure_underground_cables`,
          file:  require(`@/assets/dialogs/documents/brochure_underground_cables.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/substation_transmission_underground_cables.png`),
        },
      ],
    }
  },
  {
    name: `asset-management`,
    img: require(`@/assets/dialogs/images/asset_management.jpg`),
    content: {
      files: [
        {
          name: `brochure_asset_management`,
          file:  require(`@/assets/dialogs/documents/brochure_asset_management.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_asset_management.png`),
        },
      ],
    }
  },
  {
    name: `safe`,
    img: require(`@/assets/dialogs/images/safe.jpg`),
    video: require(`@/assets/videos/safe.mp4`),
    content: {
      files: [
        {
          name: `flyer_safe`,
          file:  require(`@/assets/dialogs/documents/flyer_safe.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_safe.png`),
        },
      ],
    }
  },
  {
    name: `magnetic-rails`,
    img: require(`@/assets/dialogs/images/magnetic_rails.jpg`),
    video: require(`@/assets/videos/magnetic_rails.mp4`),
    content: {
      files: [
        {
          name: `brochure_magnetic_rails`,
          file:  require(`@/assets/dialogs/documents/brochure_magnetic_rails.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_magnetic_rails.png`),
        },
      ],
    }
  },
  {
    name: `temporary-tower`,
    img: require(`@/assets/dialogs/images/temporary_tower.jpg`),
    video: require(`@/assets/videos/temporary_tower.mp4`),
    content: {
      files: [
        {
          name: `brochure_temporary_tower`,
          file:  require(`@/assets/dialogs/documents/brochure_temporary_tower.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/brochure_temporary_tower.png`),
        },
      ],
    }
  },
  {
    name: `drone-inspection`,
    img: require(`@/assets/dialogs/images/drone_inspection.jpg`),
    video: require(`@/assets/videos/drone_inspection.mp4`),
    content: {
      files: [
        {
          name: `flyer_drone_smart_inspection`,
          file:  require(`@/assets/dialogs/documents/flyer_drone_smart_inspection.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/flyer_drone_smart_inspection.png`),
        },
      ],
    }
  },
  {
    name: `high-voltage-line`,
    img: require(`@/assets/dialogs/images/power_lines.jpg`),
    video: require(`@/assets/videos/power_lines.mp4`),
    content: {
      files: [
        {
          name: `brochure_overhead_lines`,
          file:  require(`@/assets/dialogs/documents/brochure_overhead_lines.pdf`),
          qrCode: require(`@/assets/dialogs/qr_codes/substation_transmission_underground_cables.png`),
        },
      ],
    }
  },
  // #endregion TRANSMISSION
];
