export default {
  name: `loading`,
  tutorial: `LERNPROGRAMM`,
  startCTA: `STARTEN SIE DAS ERLEBNIS`,
  tutorialParts: [
    {
      name: `dragTuto`,
      label: `SEHEN SIE SICH MIT IHREM MAUSZEIGER UM`,
    },
    {
      name: `moveTuto`,
      label: `KLICKEN SIE AUF DIE PFEILE, UM SICH ZU BEWEGEN`,
    },
    {
      name: `spotTuto`,
      label: `KLICKEN SIE AUF DIE HOTSPOTS, UM AKTIONEN AUSZULÖSEN`,
    },
    {
      name: `menuTuto`,
      label: `KLICKEN SIE AUF DAS MENÜ, UM DIE OPTIONEN ZU ÖFFNEN`,
    },
  ]
}
