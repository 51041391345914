export default {
  name: `map`,
  label: `KART`,
  spots: [
    {
      name: `distribution`,
      label: `DISTRIBUSJON`
    },
    {
      name: `production`,
      label: `STRØM`
    },
    {
      name: `transformation`,
      label: `TRANSFORMATORSTASJON`
    },
    {
      name: `transport`,
      label: `TRANSMISJON`
    },
  ],
}