<template>
  <div
    class="omexom-dialog"
    :class="{
      visible,
      mobile: $breakpoints.isMobile
    }"
    :style="cssVars"
  >
    <template v-if="hasDialog">
      <div class="content-wrapper">
        <div
          class="image-container"
          :style="`background-image: url(${dialog.img})`"
          @click.prevent="openVideo"
        >
          <div class="video-button" v-if="dialog.video">
            <img :src="icons.video" alt="play-icon" />
          </div>
        </div>
        <div class="content-container">
          <div class="content-title">{{ dialog.title }}</div>
          <div class="title-spacer" />
          <div class="content-text-wrapper">
            <div
              class="text-column"
              v-for="(col, idx) in dialog.content.columns"
              :key="`content-column-${idx}`"
            >
              <p v-for="(txt, i) in col" :key="`col-${idx}-part-${i}`">{{ txt }}</p>
            </div>
          </div>
          <div class="dialog-files-wrapper">
            <template v-for="(file, idx) in dialog.content.files">
              <a
                class="file-button"
                :key="`${file.name}-${idx}`"
                :href="file.file"
                download
                v-if="filesAsQRCodes === false"
              >
                <div>{{ file.label }}</div>
                <div class="download-icon">
                  <svg width="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M259.405 125C262.166 125 264.405 127.239 264.405 130L264.405 333.553L333.772 264.186C335.724 262.234 338.89 262.234 340.843 264.186L345 268.343C346.952 270.296 346.952 273.462 345 275.414L262.641 357.773C259.23 361.184 253.7 361.183 250.29 357.773L167.932 275.414C165.979 273.462 165.979 270.296 167.932 268.343L172.088 264.186C174.041 262.234 177.207 262.234 179.16 264.186L248.526 333.553L248.526 130C248.526 127.239 250.765 125 253.526 125L259.405 125Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M158.441 379.091C158.441 376.882 160.232 375.091 162.441 375.091H350.49C352.699 375.091 354.49 376.882 354.49 379.091V383C354.49 385.209 352.699 387 350.49 387H162.441C160.232 387 158.441 385.209 158.441 383V379.091Z" fill="white"/></svg>
                </div>
              </a>
              <a
                class="file-button"
                :key="`${file.name}-${idx}`"
                @click.prevent="openQRCode(file)"
                v-else
              >
                <div>{{ file.label }}</div>
                <div class="download-icon">
                  <svg width="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M259.405 125C262.166 125 264.405 127.239 264.405 130L264.405 333.553L333.772 264.186C335.724 262.234 338.89 262.234 340.843 264.186L345 268.343C346.952 270.296 346.952 273.462 345 275.414L262.641 357.773C259.23 361.184 253.7 361.183 250.29 357.773L167.932 275.414C165.979 273.462 165.979 270.296 167.932 268.343L172.088 264.186C174.041 262.234 177.207 262.234 179.16 264.186L248.526 333.553L248.526 130C248.526 127.239 250.765 125 253.526 125L259.405 125Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M158.441 379.091C158.441 376.882 160.232 375.091 162.441 375.091H350.49C352.699 375.091 354.49 376.882 354.49 379.091V383C354.49 385.209 352.699 387 350.49 387H162.441C160.232 387 158.441 385.209 158.441 383V379.091Z" fill="white"/></svg>
                </div>
                <!-- <img :src="icons.download" alt="download-icon" /> -->
              </a>
            </template>
          </div>
        </div>
        <!-- <video
          :src="dialog.video"
          ref="video"
          muted="false"
          controls="false"
          playsinline
          webkit-playsinline
        /> -->
      </div>
    </template>
    <button class="close-button" @click.stop="close">×</button>
    <div class="qrcode-popup" :class="{ visible: qrCodePopupOpen }" v-if="hasDialog">
      <div class="qrcode-wrapper" v-if="fileToDownload">
        <img :src="fileToDownload.qrCode" alt="qr-code" />
        <div>{{ fileToDownload.label }}</div>
        <button class="close-button" @click.stop="closeQRCode">×</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dialog: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    qrCodePopupOpen: false,
    fileToDownload: null,
  }),

  computed: {
    filesAsQRCodes() {
      return process.env.VUE_APP_QR_CODE_DOWNLOAD === '1';
      // return false;
    },
    hasDialog() {
      const keys = Object.keys(this.dialog);

      if (!keys.includes('name')) {
        return false;
      }
      return true;
    },
    video() {
      return this.dialog.video;
    },
    cssVars() {
      return {
        '--nb-cols': (this.hasDialog ? this.dialog.content.columns.length : 1),
      };
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    async openQRCode(file) {
      this.fileToDownload = file;
      await this.wait(10);
      this.qrCodePopupOpen = true;
    },
    async closeQRCode() {
      this.qrCodePopupOpen = false;
      await this.wait(300);
      this.fileToDownload = null;
    },
    close() {
      this.$emit(`close`);
    },
    openVideo() {
      this.$emit(`open-video`, this.video);
    },
  },

  watch: {
    visible(visible) {
      visible;
    },
  },
};
</script>

<style lang="scss">

.omexom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 43, 104, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    overflow-y: auto;

    .image-container {
      width: 35%;
      height: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 2em 0;
      cursor: pointer;

      .video-button {
        width: 70px;
        height: 70px;
        background-color: rgba(8, 43, 104, 1);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform-style: preserve-3d;

        &::before {
          content: '';
          position: absolute;
          width: 90px;
          height: 90px;
          border-radius: 100px;
          background-color: rgba(255, 255, 255, 0.6);
          transform: translateZ(-1px);
          transition: background-color 0.3s ease-in-out;
        }

        img {
          width: 80%;
        }
      }


        &:hover {
          .video-button::before {
            background-color: #ffffff;
          }
        }
    }

    .content-container {
      width: 65%;
      height: 100%;
      padding: 3em 2.5em 1.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;

      .content-title {
        font-size: 1.5em;
        font-weight: 700;
      }

      .title-spacer {
        margin: 1.5em 0;
        width: 30%;
        border: 2px solid #ffffff;
      }

      .content-text-wrapper {
        flex-grow: 1;
        // margin-bottom: 1em;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        font-weight: 500;

        .text-column {
          width: calc(100% / var(--nb-cols) - 2em);
          height: 100%;
          overflow-y: scroll;
          margin: 0 0.5em;
          padding: 0 1em;
          text-align: left;
          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
          }

          &::-webkit-scrollbar-track-piece:end {
            background-color: transparent;
            margin-bottom: 1em;
          }

          &::-webkit-scrollbar-track-piece:start {
            background-color: transparent;
            margin-top: 1em;
          }
        }
      }

      .dialog-files-wrapper {
        width: 100%;
        align-self: flex-start;
        height: 10%;
        margin-top: 2em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        .file-button {
          cursor: pointer;
          width: calc((100% - 30px) / 3);
          height: calc(100%);
          padding: 0em 1em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 250px;
          text-decoration: none;
          color: #ffffff;
          font-size: 0.9em;
          font-weight: 600;
          background-color: rgb(50, 81, 139);
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

          .download-icon {
            width: 3em;

            svg {
              width: 100%;
              path {
                transition: fill 0.3s ease-in-out;
              }
            }
          }

          &:hover {
            color: rgb(50, 81, 139);
            background-color: #ffffff;

            path {
              fill: rgb(50, 81, 139);
            }
          }
        }
      }
    }
  }

  .qrcode-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .qrcode-wrapper {
      position: relative;
      padding: 2em;
      width: 25%;
      aspect-ratio: 1/1;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      color: #082b69;
    }
  }

  &:not(.mobile) {
    width: 1100px;
    max-width: 80%;
    height: 70%;
    flex-direction: row;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // & > div {
    //   width: 50%;
    // }

    // .image-wrapper {
    //   height: 100%;
    // }

    // .content-wrapper {
    //   height: 100%;
    //   font-size: 16px;
    // }
  }

  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 20px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    border: none;
    background: none;
    font-size: 30px;
    line-height: 17px;

    transition: color 0.5s ease-in-out;

    &:hover {
      color: darken(#ffffff, 20);
    }
  }

  .qrcode-popup {
    .close-button {
      padding: 15px;
      color: #082b69;

      &:hover {
        color: lighten($color: #082b69, $amount: 20)
      }
    }
  }

  @media all and (max-width: 1000px) {
    .content-wrapper {
      .content-container {
        font-size: 14px;
      }
    }
  }
}
</style>