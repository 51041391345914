export default {
  name: `loading`,
  tutorial: `Opplæringen`,
  startCTA: `START OPPLEVELSEN`,
  tutorialParts: [
    {
      name: `dragTuto`,
      label: `SE DEG RUNDT MED MUSEMARKØREN`,
    },
    {
      name: `moveTuto`,
      label: `KLIKK PÅ PILEN FOR Å BEVEGE DEG`,
    },
    {
      name: `spotTuto`,
      label: `KLIKK PÅ REFERANSEPUNKT FOR Å SETTE I GANG EN HANDLING`,
    },
    {
      name: `menuTuto`,
      label: `KLIKK PÅ MENYEN FOR Å ÅPNE FUNKSJONSVALG`,
    },
  ]
}
