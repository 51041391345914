import * as THREE from 'three'
import EvEmitter from './EvEmitter'

export default class Ray extends EvEmitter {
  constructor(ap3) {
    super()
    this.init(ap3)
  }

  init(ap3) {
    this.ap3 = ap3
    this.ray = new THREE.Raycaster()
    this.ray.near = 1
    this.ray.far = 11
    this.castList = []
    this.curIntersects = []
    this.curIntersect0 = null
    this.curSpot = null
  }

  tick() {
    this.ray.setFromCamera(this.ap3.pointer.pointer, this.ap3.cam.cam)
    this.curIntersects = this.ray.intersectObjects(this.castList)
    if(this.curIntersects.length && this.curIntersects[0].object != this.curIntersect0) {
      if(!this.ap3.pointer.isDragging) { document.querySelector(`.spaces-scene-wrapper`).style.cursor = `pointer` }
      this.curIntersect0 = this.curIntersects[0].object
      if(this.curSpot) { this.ap3.spots.fade(this.curSpot, 'out') }
      this.curSpot = this.ap3.spots[this.curIntersect0.name.slice(5).charAt(0).toLowerCase() + this.curIntersect0.name.slice(6)]
      this.ap3.spots.fade(this.curSpot, 'in')
    }
    else if(!this.curIntersects.length && this.curIntersect0) {
      if(!this.ap3.pointer.isDragging) { document.querySelector(`.spaces-scene-wrapper`).style.cursor = `grab` }
      this.ap3.spots.fade(this.curSpot, 'out')
      this.curIntersect0 = null
      this.curSpot = null
    }
  }

  addEventListener(evt) {
    this.on(evt.type, evt.cb)
  }

  removeEventListener(evt) {
    this.off(evt.type)
  }

  removeCastElement(elem) {
    this.castList = this.castList.filter(castElem => castElem.name === elem)
  }

  trig() {
    if (this.curSpot && this.curSpot.action)
      this.trigger(this.curSpot.action.type, [this.curSpot.action])
  }
}