export default {
  name: `loading`,
  tutorial: `TUTORIEL`,
  startCTA: `DÉMARRER L'EXPÉRIENCE`,
  tutorialParts: [
    {
      name: `dragTuto`,
      label: `Regardez autour de vous en déplaçant la souris`,
    },
    {
      name: `moveTuto`,
      label: `Cliquer sur les flèches pour vous déplacer`,
    },
    {
      name: `spotTuto`,
      label: `Cliquer sur les point d'accès pour déclencher des actions`,
    },
    {
      name: `menuTuto`,
      label: `Cliquer sur le menu pour ouvrir les options`,
    },
  ]
}
