export default {
  name: `loading`,
  tutorial: `TUTORIAL`,
  startCTA: `START THE EXPERIENCE`,
  tutorialParts: [
    {
      name: `dragTuto`,
      label: `Look around with your mouse cursor`,
    },
    {
      name: `moveTuto`,
      label: `Click on the arrows to move`,
    },
    {
      name: `spotTuto`,
      label: `Click on the hotspots to trigger actions`,
    },
    {
      name: `menuTuto`,
      label: `Click on the menu to open the options`,
    },
  ]
}
