import * as THREE from 'three'

export default class Gyro {
  constructor(ap3) {
    this.init(ap3)
  }

  init(ap3) {
    this.ap3 = ap3
    this.enabled = false
    this.orien = { a: 0, b: 0, g: 0 }
    this.lonQuat = new THREE.Quaternion()
    this.lonQuatOffset = new THREE.Quaternion(- Math.sqrt(1/2), 0, 0, Math.sqrt(1/2))
    this.lonVecA = new THREE.Vector3(0, 1, 0)
    this.lonVecB = new THREE.Vector3(0, 0, 0)
    this.lonVecC = new THREE.Vector3(1, 0, 0)
    this.lonEuler = new THREE.Euler()
    this.lonPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0)
    this.aziVecA = new THREE.Vector3(0, 1, 0)
    this.aziVecB = new THREE.Vector3(0, 0, 0)
    this.aziEuler = new THREE.Euler()
    this.aziPlane = new THREE.Plane(new THREE.Vector3(1, 0, 0), 0)
  }

  enableGyro() {
    if (this.enabled) { return }
    this.ap3.time.addEvent('gyro')
    this.enabled = true
  }

  disableGyro() {
    this.ap3.cam.data.dragLon = 0
    this.ap3.cam.data.softLon = 0
    this.ap3.cam.data.dragAzi = 0
    this.ap3.cam.data.softAzi = 0
    this.ap3.time.remEvent('gyro')
    this.enabled = true
  }

  tick() {
    this.lonEuler.set(this.orien.g * Math.PI/180, this.orien.a * Math.PI/180, this.orien.b * Math.PI/180, 'YZX')
    this.lonQuat.setFromEuler(this.lonEuler)
    this.lonQuat.multiply(this.lonQuatOffset)
    this.lonVecA.set(0, 1, 0)
    this.lonVecA.applyQuaternion(this.lonQuat)
    this.lonPlane.projectPoint(this.lonVecA, this.lonVecB)
    let lon = this.lonVecB.angleTo(this.lonVecC)
    if(this.lonVecB.z > 0) { this.ap3.cam.data.gyroLonSide = 2 * Math.PI - lon } else { this.ap3.cam.data.gyroLonSide = lon }

    this.aziEuler.set(this.orien.b * Math.PI/180, this.orien.a * Math.PI/180, - this.orien.g * Math.PI/180, 'YZX')
    this.aziVecA.set(0, 1, 0)
    this.aziVecA.applyEuler(this.aziEuler)
    this.aziPlane.projectPoint(this.aziVecA, this.aziVecB)
    this.ap3.cam.data.gyroAziSide = Math.min(this.ap3.cam.data.maxAzi, Math.max(this.ap3.cam.data.minAzi , - this.aziVecB.y * Math.PI/2))

    this.ap3.cam.updateCamOrientation()
  }
}