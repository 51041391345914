import * as THREE from 'three'
import EventEmitter from './EvEmitter'

export default class Pointer extends EventEmitter {
  constructor(ap3) {
    super()
    this.init(ap3)
  }

  init(ap3) {
    this.ap3 = ap3
    this.pointing = false
    this.pinching = false
    this.isDragging = false
    this.pointer = new THREE.Vector2(0, 0)
    this.iPointer = new THREE.Vector2(0, 0)
    this.dPointer = new THREE.Vector2(0, 0)
    this.iniPinchDistance = 0
    this.pinchDistance = 0

    this.ap3.canvas.addEventListener('touchstart', (e) => {
      if(e.targetTouches.length === 2) {
        this.stopPointing()
        this.iniPinchDistance = Math.sqrt(Math.pow(e.targetTouches[0].clientX - e.targetTouches[1].clientX, 2) + Math.pow(e.targetTouches[0].clientY - e.targetTouches[1].clientY, 2))
        this.pinchDistance = this.iniPinchDistance
        this.ap3.cam.data.iniPinchCamFov = this.ap3.cam.cam.fov
        this.pinching = true
        this.ap3.time.addEvent('pinching')
      } else {
        this.startPointing(e.touches[0].clientX, e.touches[0].clientY)
      }
    })
    this.ap3.canvas.addEventListener('touchend', () => {
      this.stopPointing()
    })
    this.ap3.canvas.addEventListener('touchmove', (e) => {
      if(e.targetTouches.length == 1) {
        this.setPointer(e.touches[0].clientX, e.touches[0].clientY)
      } else {
        this.pinchDistance = Math.sqrt(Math.pow(e.targetTouches[0].clientX - e.targetTouches[1].clientX, 2) + Math.pow(e.targetTouches[0].clientY - e.targetTouches[1].clientY, 2))
      }
    })

    this.ap3.canvas.addEventListener('mousedown', (e) => {
      if (e.which === 1 && e.ctrlKey !== true) { this.startPointing(e.clientX, e.clientY) }
    })
    this.ap3.canvas.addEventListener('mouseup', () => {
      this.stopPointing()
    })
    this.ap3.canvas.addEventListener('mousemove', (e) => {
      this.setPointer(e.clientX, e.clientY)
    })
    this.ap3.canvas.addEventListener('mousewheel', (e) => {
      this.ap3.cam.mouseWheelZoom(-1 * Math.sign(e.deltaY))
    })
    this.ap3.canvas.addEventListener('mouseleave', () => {
      this.stopPointing()
    })
  }

  startPointing(x, y) {
    this.setPointer(x, y)
    this.iPointer.x = this.pointer.x
    this.iPointer.y = this.pointer.y
    this.pointing = true
    this.ap3.time.addEvent('pointing')
  }

  stopPointing() { if(this.pointing) {
    if(!this.isDragging) { this.ap3.ray.trig() }
    this.pointing = false
    this.pinching = false
    this.isDragging = false
    this.trigger(`stop-dragging`)
    if(!this.ap3.ray.curIntersect) { document.querySelector(`.spaces-scene-wrapper`).style.cursor = `grab` } else { document.querySelector(`.spaces-scene-wrapper`).style.cursor = `pointer` }
    this.ap3.time.remEvent('pointing')
    this.ap3.time.remEvent('dragging')
    this.ap3.time.remEvent('pinching')
  }}

  setPointer(x, y) {
    this.pointer.x = ((x / this.ap3.sizes.w) - 0.5) * 2
    this.pointer.y = -((y / this.ap3.sizes.h) - 0.5) * 2
  }

  tickPointing() {
    if(!this.isDragging && Math.sqrt((Math.pow((this.pointer.x - this.iPointer.x), 2) + Math.pow((this.pointer.y - this.iPointer.y) * this.ap3.sizes.h / this.ap3.sizes.w, 2)) / 4) > this.ap3.cam.params.safeZoneFactor/100) {
      this.iPointer.x = this.pointer.x
      this.iPointer.y = this.pointer.y
      this.ap3.cam.setIRotation()
      document.querySelector(`.spaces-scene-wrapper`).style.cursor = `grabbing`
      this.ap3.time.addEvent('dragging')
      this.ap3.time.addEvent('smoothRotation')
      this.isDragging = true
      this.trigger(`start-dragging`)
    }
  }
}