export default [
  {
    name: `splash_screen`,
    welcome: `Welcome on the Omexom World, a 360 universe with all of Omexom's expertise. This immersive and entertaining experience will show some of our offers related to different TSO and DSO challenges such as flexibility, efficiency, resilience, maintenance and safety.`,
    langSelectorCTA: `CHOOSE YOUR LANGUAGE`,
    startCTA: `START THE EXPERIENCE`,
    image: {
      backgroundImg: require(`@/assets/splashscreen/splashscreen_background.jpg`),
      logo: require(`@/assets/logos/logo_white.png`),
    },
  }
]