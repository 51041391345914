import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'

const textureLoader = new THREE.TextureLoader()
// import { textureLoader } from '@/three/loadTexture'

export default class AssetsLoader {
  constructor() {
    this.init()
  }

  init() {
    this.texs = {}
    this.glbs = {}
    this.cubs = {}
    this.vids = {}
    this.mats = {}
    this.texLoader = textureLoader
    this.glbLoader = new GLTFLoader()
    this.cubLoader = new THREE.CubeTextureLoader()
  }

  async load(assets, handler) {
    const promises = []
    const assetsToLoad = assets instanceof Array ? assets : [assets]
    for(const asset of assetsToLoad) { switch(asset.type) {
      case 'glb': promises.push(this.#loadGlb(asset)) ; break
      case 'tex': promises.push(this.#loadTex(asset)) ; break
      case 'cub': promises.push(this.#loadCub(asset)) ; break
      case 'vid': promises.push(this.#loadVid(asset)) ; break
      case 'matBas' : case 'matStd' : case 'matCap' : this.mats[asset.name] = asset
    }}
    const files = await Promise.all(promises)
    handler.glbs = this.glbs
    handler.texs = this.texs
    handler.cubs = this.cubs
    handler.vids = this.vids
    handler.mats = this.mats
    if (files.length === 1) { return files[0] }
    return files
  }

  disposeSceneTextures(scene) {
    for (let tex of Object.keys(this.texs)) {
      if (scene === tex) {
        this.texs[tex].dispose()
        delete this.texs[tex]
        delete this.mats[tex]
      }
    }
  }

  destroy() {
    for (let tex of Object.keys(this.texs)) { this.texs[tex].dispose() }
    for (let cub of Object.keys(this.cubs)) { this.cubs[cub].dispose() }
  }

  async #loadTex(asset) { return new Promise(resolve => {
    this.texLoader.load(asset.url, file => {
      this.texs[asset.name] = file
      if(asset.flip) { this.texs[asset.name].flipY = false}
      if(asset.tile) {
        this.texs[asset.name].wrapS = THREE.RepeatWrapping
        this.texs[asset.name].wrapT = THREE.RepeatWrapping
      }
      resolve(file)
    })
  })}
  async #loadGlb(asset) { return new Promise(resolve => {
    this.glbLoader.load(asset.url, file => {
      this.glbs[asset.name] = file
      resolve(file)
    })
  })}
  async #loadCub(asset) { return new Promise(resolve => {
    this.cubLoader.load(asset.url, file => {
      this.cubs[asset.name] = file
      resolve(file)
    })
  })}
  async #loadVid(asset) { return new Promise(resolve => {
    const file = asset.url ////////////
    this.cubs[asset.name] = file ////////////
    resolve(file)
  })}
}