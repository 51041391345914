export default {
  name: `map`,
  label: `KARTE`,
  spots: [
    {
      name: `distribution`,
      label: `VERTEILUNG`
    },
    {
      name: `production`,
      label: `ERZEUGUNG`
    },
    {
      name: `transformation`,
      label: `UMWANDLUNG`
    },
    {
      name: `transport`,
      label: `ÜBERTRAGUNG`
    },
  ],
}